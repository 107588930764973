import {createTheme} from "@mantine/core";

export const theme = createTheme(
    {
        fontFamily: 'Inter',
        breakpoints: {
            xs: '30em',
            sm: '48em',
            md: '64em',
            lg: '74em',
            xl: '90em',
        },
        fontSizes: {
            xs: '12',
            sm: '14',
            md: '16',
            lg: '20',
            xl: '22',
        },
        headings: {
            // properties for all headings
            fontWeight: '400',
            fontFamily: 'Inter',

            // properties for individual headings, all of them are optional
            sizes: {
                h1: {
                    fontWeight: '500',
                    fontSize: '32px',
                    lineHeight: '1.4',
                },
                h2: {
                    fontWeight: '500',
                    fontSize: '28px',
                    lineHeight: '1.5'
                },
                // ...up to h6
                h6: {fontWeight: '900'},
            },
        },
        colors: {
            'flexinet': [
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74',
                '#194a74'],
        },
        primaryColor: 'flexinet',
    })


