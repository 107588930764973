import {Meter,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {DimensionsTable} from "../../../components/DimensionsTable.tsx";


export const DimensionsPropertyCard = ({record}: { record: Meter }) => {

    const properties = [
        {
            label: 'Dimensions',
            value: record.dimensions ? <DimensionsTable dimensions={record.dimensions.map(d => ({
                name: d.name,
                value: d.apiKey
            }))}/> : null,
            span: 12,
        }
    ] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};
