import {z} from "zod";
import {BasePlatformObject} from "../../../schemas";


export const BillingProviderSchema = BasePlatformObject.extend(
    {
        key: z.string()
              .min(1, "key is required"),
        name: z.string()
               .min(1, "name is required"),
        description: z.string()
                      .nullable(),
        status: z.union([
                            z.literal('Enabled'),
                            z.literal('Disabled')
                        ]),

    })

export const ListBillingProvidersDataSchema = z.object(
    {
        listBillingProviders: z.object(
            {
                items: z.array(BillingProviderSchema)
            })
    })

export const ListBillingProvidersResult = z.object(
    {
        data: ListBillingProvidersDataSchema
    })
