import {
    getUsageRecord,
    listUsageRecords
} from './queries';
import {
    createUsageRecord,
    deleteUsageRecord,
    updateUsageRecord,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateUsageRecord,
    DeleteUsageRecord,
    GetUsageRecord,
    ListUsageRecords,
    UpdateUsageRecord,
    UsageRecord
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";
import {GetUsageRecordParser,} from '../schemas/index.ts';


const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListUsageRecords>>({
                                                                                  query: listUsageRecords,
                                                                                  variables: {
                                                                                      filter: filter
                                                                                  },
                                                                              });
        return response.data?.listUsageRecords.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetUsageRecord>>({
                                                                                query: getUsageRecord,
                                                                                variables: {id: id},
                                                                            });

        const parsed = GetUsageRecordParser.parse(response)
        return parsed.data?.getUsageRecord;

    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateUsageRecord>>({
                                                                                   query: createUsageRecord,
                                                                                   variables: {input: payload},
                                                                               });
        console.debug('in the service response', response);
        notifySuccess('Usage record created');
        return response.data?.createUsageRecord;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: UsageRecord) => {

    console.debug('IN UPDATE', id, record);


    try {
        const response = await client.graphql<GraphQLQuery<UpdateUsageRecord>>({
                                                                                   query: (updateUsageRecord),
                                                                                   variables: {input: record},
                                                                               });
        notifySuccess('Usage record updated');
        return response.data?.updateUsageRecord;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteUsageRecord>>({
                                                                                   query: (deleteUsageRecord),
                                                                                   variables: {input: {id: id}},

                                                                               });
        notifySuccess('Usage record deleted')
        return response.data?.deleteUsageRecord;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
