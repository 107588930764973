import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          status: Yup.string()
                                                     .required('Required'),
                                          billingInfo: Yup.object()
                                                          .shape({
                                                                     billingProviderId: Yup.string()
                                                                                           .required('Required'),
                                                                     billingProviderRecordId: Yup.string(),
                                                                 }),
                                          contactInfo: Yup.object()
                                                          .shape({
                                                                     email: Yup.string()
                                                                               .email('Invalid email'),
                                                                     phone: Yup.string(),
                                                                     website: Yup.string()
                                                                 }),
                                      });
