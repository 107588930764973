import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useServicePlans} from '../hooks/useServicePlans';
import {useServicePlansSelection} from "../hooks/useServicePlansSelection.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useServicePlans();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Service Plan',
                             onClick,
                         }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <FlexiAddButton onClick={onClick}>
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useServicePlans();

    const {
        selection,
        handleClearSelection,
    } = useServicePlansSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes(rbacActions.DeleteServicePlan)) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes(rbacActions.CreateServicePlan)) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Service Plans')}
            tools={useTools()}
        />
    );
};
