import {stage} from '../stage';


const billingAPI = {
    prod: {
        endpoint: 'https://awqbc7agkrdxza4di3ggk2tsnq.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    test: {
        endpoint: 'https://jwbq5ckinrd2bctdhu47c3jjva.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    dev: {
        endpoint: 'https://43ruvo3zkbf4jkj7zpsisoubmi.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    ide: {
        endpoint: 'https://43ruvo3zkbf4jkj7zpsisoubmi.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
};

export const appSyncConfig = billingAPI[stage];

