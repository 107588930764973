import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          customerId: Yup.string()
                                                         .required('Required'),
                                          meterId: Yup.string()
                                                      .required('Required'),
                                          meterValue: Yup.number()
                                                         .required('Required'),
                                          timestamp: Yup.string()
                                                        .required('Required'),
                                          dimensions: Yup.array()
                                                         .of(Yup.object()
                                                                .shape({
                                                                           apiKey: Yup.string()
                                                                                      .required('Required'),
                                                                           value: Yup.string()
                                                                                     .required('Required'),
                                                                       })),
                                      });
