import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../profile/Profile';

import {EditPageNavigation} from '../navigation/EditPageNavigation';
import {useUsageRecord} from '../hooks/useUsageRecord.ts';
import {useUsageRecordsSelection} from '../hooks/useUsageRecordsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {useUsageRecords} from "../hooks/useUsageRecords.ts";


export const EditPage = () => {

    const {id: usageRecordId} = useParams();

    if (typeof usageRecordId === 'undefined') {
        throw new Error('usageRecordId is required parameter');
    }

    const {fetchRecords} = useUsageRecords();

    const {
        record: usageRecord,
        fetchRecord,
        updateRecord,
    } = useUsageRecord(usageRecordId);

    const {handleClearSelection} = useUsageRecordsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [usageRecordId]);

    if (!usageRecord) {
        return <PlatformLoader message='Loading usageRecord...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={usageRecord.name ? usageRecord.name : usageRecord.id}
            pageNavigation={<EditPageNavigation component={usageRecord}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={usageRecord}
                    />

                    {/*
                    <AccountsPage/>
*/}

                </Stack>
                :
                <RecordForm
                    record={usageRecord}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
