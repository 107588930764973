import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../navigation/EditPageNavigation';
import {useMeteringRecord} from '../hooks/useMeteringRecord.ts';
import {useMeteringRecordsSelection} from '../hooks/useMeteringRecordsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {useMeteringRecords} from "../hooks/useMeteringRecords.ts";


export const EditPage = () => {

    const {id: meteringRecordId} = useParams();

    if (typeof meteringRecordId === 'undefined') {
        throw new Error('meteringRecordId is required parameter');
    }

    const {fetchRecords} = useMeteringRecords();

    const {
        record: meteringRecord,
        fetchRecord,
        updateRecord,
    } = useMeteringRecord(meteringRecordId);

    const {handleClearSelection} = useMeteringRecordsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [meteringRecordId]);

    if (!meteringRecord) {
        return <PlatformLoader message='Loading meteringRecord...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={meteringRecord.name ? meteringRecord.name : meteringRecord.id}
            pageNavigation={<EditPageNavigation component={meteringRecord}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={meteringRecord}
                    />

                    {/*
                    <AccountsPage/>
*/}

                </Stack>
                :
                <RecordForm
                    record={meteringRecord}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
