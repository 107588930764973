export const createOrder = `
    mutation createOrder($input: CreateOrderInput!) {
        createOrder(input:$input) {
            id
            customerId
            subscriptionId       
            created
            updated  
    }
}`;

export const updateOrder = `
    mutation updateOrder($input: UpdateOrderInput!) {
        updateOrder(input:$input){
            id
            customerId
            subscriptionId  
            created
            updated       
        }
}`;

export const deleteOrder = `
    mutation deleteOrder($input: DeleteOrderInput!) {
        deleteOrder(input:$input) 
}`;
