import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {UsageRecord} from '../types';

export const columns = [
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: UsageRecord) => <NavLink
            to={`/usage-records/${item.id}`}>{formatTimestamp(item.created as string)}</NavLink>,
    },
    {
        title: I18n.get('Username'),
        width: '1fr',
        render: (item: UsageRecord) => item.username,
    },
    {
        title: I18n.get('Action'),
        width: '1fr',
        render: (item: UsageRecord) => item.action,
    },
    {
        title: I18n.get('UsageType'),
        width: '1fr',
        render: (item: UsageRecord) => item.usageType,
    },
];
