import {z} from "zod";

export const ObjectWithId = z.object(
    {
        id: z.string()
             .uuid()
             .min(1, 'id is required'),
    })

export const BasePlatformObject = ObjectWithId.extend(
    {
        created: z.string()
                  .datetime()
                  .min(1, 'created is required'),
        createdByUsername: z.string()
                            .optional(),
        createdBy: z.object({
                                givenName: z.string(),
                                familyName: z.string()
                            })
                    .optional(),
        updated: z.string()
                  .datetime()
                  .optional(),
        updatedByUsername: z.string()
                            .optional(),
        updatedBy: z.object({
                                givenName: z.string(),
                                familyName: z.string()
                            })
                    .optional(),

    })