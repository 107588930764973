import {
    deleteUsageRecord,
    fetchUsageRecord,
    saveUsageRecord,
    updateUsageRecord,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {UsageRecord} from '../types';

export const useUsageRecord = (usageRecordId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, usageRecordId ? usageRecordId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof usageRecordId === 'undefined') {
            throw new Error('usageRecordId is required parameter');
        }
        dispatch(fetchUsageRecord(usageRecordId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: UsageRecord) => {
        dispatch(saveUsageRecord(values));
    };

    const updateRecord = (values: UsageRecord) => {
        if (typeof usageRecordId === 'undefined') {
            throw new Error('usageRecordId is required parameter');
        }

        dispatch(updateUsageRecord({
                                       id: usageRecordId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof usageRecordId === 'undefined') {
            throw new Error('usageRecordId is required parameter');
        }
        dispatch(deleteUsageRecord(usageRecordId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
