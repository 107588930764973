import {
    Field,
    FormikProps
} from 'formik';

import {
    FormikSelect,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Container} from "@mantine/core";
import {BillingProvider} from "../types.ts";


export const FormComponent = (props: FormikProps<BillingProvider>) => {

    const editing = typeof props.values.id !== 'undefined';

    return (
        <Container>
            <PlatformFieldset legend={'Billing Provider'}>

                <Field
                    name='key'
                    label='Key'
                    description='Please enter unique key'
                    component={FormikTextInput}
                    disabled={editing}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='name'
                    label='Name'
                    description='Please enter the provider name'
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Description'
                    description='Please enter provider description'
                    component={FormikTextarea}
                    my={'xl'}
                />

                {editing ? <Field
                    name='status'
                    label='Status'
                    description='Please enter provider status'
                    data={[{
                        label: 'Enabled',
                        value: 'Enabled'
                    }, {
                        label: 'Disabled',
                        value: 'Disabled'
                    }]}
                    component={FormikSelect}
                    my={'xl'}
                /> : null}

            </PlatformFieldset>

        </Container>
    );
};
