import {combineReducers} from 'redux';

import recordsReducer from './recordsSlice';
import {VisibilitySliceFor} from '../../../store/VisibilitySliceFor';
import {SelectionSliceFor} from '../../../store/SelectionSliceFor';

const entityKey = 'meters';

const visibilitySlice = VisibilitySliceFor(entityKey);
const selectionSlice = SelectionSliceFor(entityKey);

export const {
    setPage,
    incrementPage,
    decrementPage,
    setPageSize,
    resetPageSize,
    setKeyword,
    clearKeyword,
    show,
    hide,
    toggleVisibility,
    setTableColumns,
    addTableColumn,
} = visibilitySlice.actions;

export const {
    addToSelection,
    removeFromSelection,
    clearSelection,
} =
    selectionSlice.actions;

export const MetersReducer = combineReducers({
                                                 records: recordsReducer,
                                                 selection: selectionSlice.reducer,
                                                 visibility: visibilitySlice.reducer,
                                             });

