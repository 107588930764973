import {
    getOrder,
    listOrders
} from './queries';
import {
    createOrder,
    deleteOrder,
    updateOrder,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Order} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";


export type ListOrders = {
    listOrders: { items: Order[] }
}

export type GetOrder = {
    getOrder: Order
}

export type CreateOrder = {
    createOrder: Order
}

export type UpdateOrder = {
    updateOrder: Order
}

export type DeleteOrder = {
    deleteOrder: string
}


const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListOrders>>({
                                                                            query: listOrders,
                                                                            variables: {filter: filter},
                                                                        });
        return response.data?.listOrders.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetOrder>>({
                                                                          query: getOrder,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getOrder;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateOrder>>({
                                                                             query: createOrder,
                                                                             variables: {input: payload},
                                                                         });
        console.debug('in the service response', response);
        notifySuccess('Order created');
        return response.data?.createOrder;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Order) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateOrder>>({
                                                                             query: (updateOrder),
                                                                             variables: {input: record},
                                                                         });
        notifySuccess('Order updated')
        return response.data?.updateOrder;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteOrder>>({
                                                                             query: (deleteOrder),
                                                                             variables: {input: {id: id}},

                                                                         });
        notifySuccess('Order deleted')
        return response.data?.deleteOrder;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
