import {BillingAccount,} from '../types';
import {
    SimplePageContentWrapper,
    TabbedComponent,
    TabComponentTab,
} from "@flexinet/ui-components";
import {
    Grid,
    Group,
    Stack
} from "@mantine/core";
import {IndexPage as OrdersPage} from "../../orders/pages/IndexPage.tsx";
import {IndexPage as SubscriptionsPage} from "../../subscriptions/pages/IndexPage.tsx";
import {IndexPage as PaymentProfilesPage} from "../../payment-profiles/pages/IndexPage.tsx";

import {MetadataPropertyCard} from "./MetadataPropertyCard.tsx";
import {InformationPropertyCard} from "./InformationPropertyCard.tsx";
import {CurrentInvoiceWidget} from "./CurrentInvoiceWidget.tsx";
import {LastPaymentWidget} from "./LastPaymentWidget.tsx";
import {BillingInfoPropertyCard} from "./BillingInfoPropertyCard.tsx";


export const Profile = ({record}: { record: BillingAccount }) => {


    const tabs = [
        {
            title: 'Overview',
            component: (
                <Stack gap={'xl'}>
                    <SimplePageContentWrapper>
                        <Group grow gap={'xl'}>
                            <CurrentInvoiceWidget record={record}/>
                            <LastPaymentWidget record={record}/>
                        </Group>
                    </SimplePageContentWrapper>
                    <SubscriptionsPage/>
                </Stack>),
        },
        {
            title: 'Orders',
            component: <OrdersPage/>,
        },
        {
            title: 'Payment Profiles',
            component: <PaymentProfilesPage/>,
        },
    ] as TabComponentTab[];

    return (
        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 4
            }}>
                <Stack gap={'xl'}>
                    <InformationPropertyCard record={record}/>
                    <BillingInfoPropertyCard record={record}/>
                    <MetadataPropertyCard record={record}/>
                </Stack>
            </Grid.Col>
            <Grid.Col span={{
                base: 12,
                lg: 8
            }}>
                <TabbedComponent tabs={tabs}/>
            </Grid.Col>
        </Grid>
    );


};
