import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {usePaymentProfiles} from '../hooks/usePaymentProfiles.ts';
import {usePaymentProfilesSelection} from "../hooks/usePaymentProfilesSelection.ts";
import {useParams} from "react-router-dom";

export const DeleteRecordButton = () => {

    const {id: billingAccountId} = useParams()

    if (typeof billingAccountId === 'undefined') {
        throw new Error('billingAccountId is required parameter')
    }

    const {deleteRecords} = usePaymentProfiles(billingAccountId);

    const {
        selection,
        handleClearSelection,
    } = usePaymentProfilesSelection(billingAccountId);

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};

