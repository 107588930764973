import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useOrders} from '../hooks/useOrders.ts';

export const ReloadRecordsButton = () => {

    const {fetchRecords} = useOrders();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
