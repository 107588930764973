import {
    getRatePlan,
    listRatePlans
} from './queries';
import {
    createRatePlan,
    deleteRatePlan,
    updateRatePlan,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {RatePlan} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";


export type ListRatePlans = {
    listRatePlans: { items: RatePlan[] }
}

export type GetRatePlan = {
    getRatePlan: RatePlan
}

export type CreateRatePlan = {
    createRatePlan: RatePlan
}

export type UpdateRatePlan = {
    updateRatePlan: RatePlan
}

export type DeleteRatePlan = {
    deleteRatePlan: string
}


const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListRatePlans>>({
                                                                               query: listRatePlans,
                                                                               variables: {filter: filter},
                                                                           });
        return response.data?.listRatePlans.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetRatePlan>>({
                                                                             query: getRatePlan,
                                                                             variables: {id: id},
                                                                         });
        return response.data?.getRatePlan;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateRatePlan>>({
                                                                                query: createRatePlan,
                                                                                variables: {input: payload},
                                                                            });
        console.debug('in the service response', response);
        notifySuccess('Rate Plan created');
        return response.data?.createRatePlan;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: RatePlan) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateRatePlan>>({
                                                                                query: (updateRatePlan),
                                                                                variables: {input: record},
                                                                            });
        notifySuccess('Rate Plan updated');
        return response.data?.updateRatePlan;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteRatePlan>>({
                                                                                query: (deleteRatePlan),
                                                                                variables: {input: {id: id}},

                                                                            });
        notifySuccess('Rate Plan deleted')
        return response.data?.deleteRatePlan;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
