export const listServicePlans = `
   query listServicePlans {
       listServicePlans {
           items {
             id
             name
             description
             status
             productIds
             products {
               items {
                 id
                 name
                 description
               }
               nextToken
             }
             createdByUsername
             created
             updated
           }
       }
   }
`

export const getServicePlan = `
   query getServicePlan($id:ID!) {
          getServicePlan(id:$id) {
            id
            name
            description
            status
            productIds
            products {
              items {
                id
                name
                description
              }
              nextToken
            }
            createdByUsername
            created
            updated
         }
}`