import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Order} from '../types';

export const columns = [
    {
        title: I18n.get('Order Date'),
        width: '1fr',
        render: (item: Order) => <NavLink to={`/orders/${item.id}`}>{formatTimestamp(item.created as string)}</NavLink>,
    },
    {
        title: I18n.get('Subscription'),
        width: '1fr',
        render: (item: Order) => <NavLink to={`/subscriptions/${item.subscriptionId}`}>{item.subscriptionId}</NavLink>,
    },
];
