export const listSubscriptions = `
   query listSubscriptions {
       listSubscriptions {
           items {
              id
              name
              customerId
              productId
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getSubscription = `
   query getSubscription($id:ID!) {
       getSubscription(id:$id) {
         id
         name
         customerId
         productId    
         created
         updated
       } 
}`;
