import {BillingAccount,} from '../types';
import {
    DashboardWidget,
    WidgetBody,
    WidgetDataPoint,
    WidgetFooter,
    WidgetHeader,
    WidgetType
} from "@flexinet/ui-components";
import {NavLink} from "react-router-dom";


export const LastPaymentWidget = ({record}: { record: BillingAccount }) => {


    const lastPaymentAmountWidget: WidgetType = {
        header: {
            title: `Last Payment`,
        },
        body: {
            dataPoints: [
                {
                    value: 899,
                    label: `For Invoice: ${record.name}`,
                    color: 'green',
                },
            ] as WidgetDataPoint[],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'Payment Details',
                    component: {NavLink},
                    to: '/payments',
                    visible: true,
                },
            ],
        }
    };

    return (
        <DashboardWidget
            header={<WidgetHeader  {...lastPaymentAmountWidget.header}/>}
            body={<WidgetBody {...lastPaymentAmountWidget.body}/>}
            footer={<WidgetFooter {...lastPaymentAmountWidget.footer}/>}
        />
    );


};
