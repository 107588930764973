import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          productIds: Yup.array()
                                                         .of(Yup.string())
                                                         .min(1, 'At least one product is required'),
                                      });
