import {Meter,} from '../types';
import {
    FlexiButton,
    SimplePageContentWrapper
} from "@flexinet/ui-components";
import {
    Container,
    Grid,
    Group,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {MetadataPropertyCard} from "./MetadataPropertyCard.tsx";
import {InformationPropertyCard} from "./InformationPropertyCard.tsx";
import {DimensionsPropertyCard} from "./DimensionsPropertyCard.tsx";
import {useMeteringRecord} from "../../metering-records/hooks/useMeteringRecord.ts";
import {RecordForm as MeteringRecordForm} from "../../metering-records/forms/RecordForm.tsx";
import {useState} from "react";
import {useScrollIntoView} from "@mantine/hooks";


export const Profile = ({record}: { record: Meter }) => {
    const {
        scrollIntoView,
        targetRef
    } = useScrollIntoView<HTMLDivElement>({
                                              offset: 300
                                          });

    const [
        ingested,
        setIngested
    ] = useState(false)


    const {
        record: meteringRecord,
        createRecord,
    } = useMeteringRecord();

    const updated = {
        ...meteringRecord, ...{
            meterId: record.id,
            timestamp: new Date()
        }
    }

    const handleIngestMeteringRecord = (record: any) => {
        createRecord(record);
        setIngested(true)
        scrollIntoView()
    }

    return (
        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 4
            }}>
                <Stack gap={'xl'}>
                    <InformationPropertyCard record={record}/>
                    <DimensionsPropertyCard record={record}/>
                    <MetadataPropertyCard record={record}/>
                </Stack>
            </Grid.Col>
            <Grid.Col span={{
                base: 12,
                lg: 8
            }}>
                <SimplePageContentWrapper>
                    <Stack gap={'xl'} ref={targetRef}>
                        <Title order={2}>Ingest Meter</Title>
                        {ingested ?
                            <Container>
                                <Group>
                                    <Text>Meter Ingested. Click continue to ingest another meter.</Text>
                                    <FlexiButton
                                        onClick={() => setIngested(false)}>Continue</FlexiButton>
                                </Group>
                            </Container> :
                            <MeteringRecordForm record={updated} handleSubmit={handleIngestMeteringRecord}/>}
                    </Stack>
                </SimplePageContentWrapper>
            </Grid.Col>
        </Grid>
    );


};
