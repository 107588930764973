export const listBillingAccounts = `
   query listBillingAccounts {
       listBillingAccounts {
           items {
              id
              name
              description
              status
              billingProviderId
              billingProviderRecordId
              created
              createdByUsername
              updated
              updatedByUsername
           }
       }
   }
`;

export const getBillingAccount = `
   query getBillingAccount($id:ID!) {
       getBillingAccount(id:$id) {
         id
         name
         description 
         status
         billingProviderId
         billingProviderRecordId
         created
         createdByUsername
         updated
         updatedByUsername
       } 
}`;
