import {UsageRecord,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const Profile = ({record}: { record: UsageRecord }) => {

    const properties = [
        {
            label: 'Username',
            value: record.username as string,
            span: 6,
        }, {
            label: 'Usage Type',
            value: record.usageType,
            span: 6,
        }, {
            label: 'Action',
            value: record.action,
            span: 6,
        }, {
            label: 'Record ID',
            value: record.recordId,
            span: 6,
        }, {
            label: 'Value',
            value: record.value,
            span: 6,
        },
        {
            label: 'Created',
            value: record.created,
            formatAs: 'Created',
            span: 6,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (<PlatformPropertyCard properties={allowedProperties}/>);


};
