import {Field} from 'formik';

import {
    FormikRadioCardGroup,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {SimpleGrid} from "@mantine/core";
import {BillingProvidersDropdown} from "../../billing-providers/inputs/BillingProvidersDropdown.tsx";


export const FormComponent = () => {

    return (
        <SimpleGrid cols={2}>
            <PlatformFieldset legend={'BillingAccount'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the billingAccount name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='status'
                    label='Status'
                    description='Please enter billingAccount status'
                    component={FormikRadioCardGroup}
                    data={[{
                        label: 'Active',
                        value: 'Active'
                    }, {
                        label: 'Inactive',
                        value: 'Inactive'
                    }]}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Description'
                    description='Please enter billingAccount description'
                    component={FormikTextarea}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Billing Info'}>


                <Field
                    name={'billingProviderId'}
                    label={'Billing Provider ID'}
                    component={BillingProvidersDropdown}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='billingProviderRecordId'
                    label='Billing Provider Record ID'
                    description='Please enter record ID'
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='billingZip'
                    label='Billing ZIP'
                    description='Please enter billing ZIP'
                    component={FormikTextInput}
                    my={'xl'}
                />


            </PlatformFieldset>

        </SimpleGrid>
    );
};
