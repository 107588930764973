import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useBillingAccounts} from '../hooks/useBillingAccounts.ts';
import {useBillingAccountsSelection} from "../hooks/useBillingAccountsSelection.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useBillingAccounts();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Billing Account',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useBillingAccounts();

    const {
        selection,
        handleClearSelection,
    } = useBillingAccountsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [];

    tools.push(<ComponentsReloadButton key='reload'/>);

    if (allowedActions.includes(rbacActions.DeleteBillingAccount)) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes(rbacActions.CreateBillingAccount)) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Billing Accounts')}
            tools={useTools()}
        />
    );
};
