export const listOrders = `
   query listOrders {
       listOrders {
           items {
              id
              customerId
              subscriptionId
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getOrder = `
   query getOrder($id:ID!) {
       getOrder(id:$id) {
         id
         customerId
         subscriptionId    
         created
         updated
       } 
}`;
