import {Badge} from "@mantine/core";

export const PaymentProfileStatusBadge = ({status}: { status: string }) => {
    let color = '';
    if (status === 'Active') {
        color = 'green';
    } else if (status === 'Inactive') {
        color = 'gray';
    }
    return <Badge color={color}>{status}</Badge>;
};
