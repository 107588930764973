import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useSubscriptions} from '../hooks/useSubscriptions.ts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useSubscriptionsSelection} from '../hooks/useSubscriptionsSelection.ts';
import {ISubscriptionTableColumn,} from '../types';
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {useSubscriptionsPaging} from "../hooks/useSubscriptionsPaging.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()

    const {
        user,
    } = useAuth()

    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;

    const {
        columns,
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
    } = useSubscriptions();

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = useSubscriptionsPaging();

    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useSubscriptionsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: ISubscriptionTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeleteSubscription)) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}/></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);

        return cols;

    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
