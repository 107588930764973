import {Customer,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty,
} from "@flexinet/ui-components";

import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const BillingInfoPropertyCard = ({record}: { record: Customer }) => {

    const properties = [
        {
            label: 'Billing Provider',
            value: record.billingInfo?.billingProviderId as string,
            span: 12,
        }, {
            label: 'Billing Provider Record ID',
            value: record.billingInfo?.billingProviderRecordId as string,
            span: 12,
        }, {
            label: 'Billing Zip',
            value: record.billingInfo?.billingZip as string,
            span: 12,
        }] as PlatformPropertyCardProperty[];


    const {filterAllowed} = useAllowedItems()

    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];


    return (<PlatformPropertyCard properties={allowedProperties}/>);


};
