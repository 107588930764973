import {RecordForm} from '../forms/RecordForm';


import {useCustomer} from '../hooks/useCustomer.ts';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useCustomer();

    return (
        <FormPageLayout
            title='Add Customer'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
