export const createBillingProvider = `
    mutation createBillingProvider($input: CreateBillingProviderInput!) {
        createBillingProvider(input:$input) {
            id
            key
            name
            description   
            status    
            created
            updated  
    }
}`;

export const updateBillingProvider = `
    mutation updateBillingProvider($input: UpdateBillingProviderInput!) {
        updateBillingProvider(input:$input){
            id
            key
            name
            description
            status  
            created
            updated       
        }
}`;

export const deleteBillingProvider = `
    mutation deleteBillingProvider($input: DeleteBillingProviderInput!) {
        deleteBillingProvider(input:$input) 
}`;
