import {PlatformNavbar} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus.tsx";
import {
    Center,
    Text,
    Title
} from "@mantine/core";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalNavbar = () => {

    const {allowedActions} = useVerifiedPermissions()

    const items = getMenuItems()

    const menuItems = items.filter((item) => {
        return allowedActions.includes(item.rbac as string)
    });

    return <PlatformNavbar
        navbarHeader={<Center p={'sm'}><Title order={4}><Text tt={"uppercase"}>Billing Console</Text></Title></Center>}
        menuItems={menuItems}/>
};