import {Customer,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty,
} from "@flexinet/ui-components";

import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {CustomerStatusBadge} from "../components/CustomerStatusBadge.tsx";


export const InformationPropertyCard = ({record}: { record: Customer }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Status',
            value: <CustomerStatusBadge status={record.status}/>,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }] as PlatformPropertyCardProperty[];


    const {filterAllowed} = useAllowedItems()

    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];


    return (<PlatformPropertyCard properties={allowedProperties}/>);


};
