import {Field} from 'formik';

import {
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Container} from "@mantine/core";


export const FormComponent = ({values}: { values: { id: string } }) => {

    const editMode = values.id ? true : false;

    return (
        <Container>
            <PlatformFieldset legend={'Product'}>
                <Field
                    name='name'
                    label='Product Name'
                    description='Please enter the product name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            {editMode ? <>

                <PlatformFieldset legend={'Description'}>

                    <Field
                        name='description'
                        label='Description'
                        description='Please enter short product description'
                        component={FormikTextarea}
                        withAsterisk
                        my={'xl'}
                    />

                </PlatformFieldset>

            </> : null}


        </Container>
    );
};
