import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {RecordForm} from '../forms/RecordForm';

import {
    PlatformLoader,
    SimplePageHeader,
    SimplePageLayout,
} from '@flexinet/ui-components';
import {Profile} from '../profile/Profile';

import {EditPageNavigation} from '../navigation/EditPageNavigation';
import {useServicePlan} from '../hooks/useServicePlan';
import {useServicePlansSelection} from '../hooks/useServicePlansSelection';
import {useEditPageTools} from '../hooks/useEditPageTools';
import {RecordLogo} from "../images/RecordLogo.tsx";


export const EditPage = () => {

    const {id: componentId} = useParams();

    if (typeof componentId === 'undefined') {
        throw new Error('componentId is required parameter');
    }

    const {
        record: component,
        fetchRecord,
        updateRecord,
    } = useServicePlan(componentId);

    const {handleClearSelection} = useServicePlansSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [componentId]);

    if (!component) {
        return <PlatformLoader message='Loading application...Please wait'/>;
    }


    return (
        <SimplePageLayout
            pageHeader={<SimplePageHeader
                title={component.name ? component.name : component.id}
                icon={<RecordLogo record={component} size={'md'}/>}
                tools={tools}/>}
            pageNavigation={<EditPageNavigation component={component}/>}
        >


            {mode === 'view' ?
                <Profile
                    record={component}
                />
                :
                <RecordForm
                    record={component}
                    handleSubmit={updateRecord}
                />}


        </SimplePageLayout>
    );
};
