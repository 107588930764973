import {useEffect} from 'react';

import {useProducts} from '../hooks/useProducts.ts';
import {FieldProps} from 'formik';
import {Product} from '../types';
import {MultiSelect} from "@mantine/core";

export const ProductsMultiSelect = ({
                                        field: {
                                            name,
                                            value,
                                        },
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps) => {


    const {
        items: products,
        fetchRecords
    } = useProducts();

    useEffect(() => {
        fetchRecords();
    }, []);

    const data = products.map((component: Product) => {
        return {
            value: component.id,
            label: component.name,
        };
    });


    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
