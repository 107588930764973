import {BillingAccount,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty,
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const MetadataPropertyCard = ({record}: { record: BillingAccount }) => {


    const metadata = [
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBillingAccount',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }, {
            label: 'Updated By',
            value: typeof record.updatedBy !== 'undefined' && record.updatedBy !== null ? [record.updatedBy.givenName, record.updatedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBillingAccount',
            span: 12,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()

    const metadataAllowedProperties = filterAllowed(metadata) as PlatformPropertyCardProperty[];


    return (<PlatformPropertyCard properties={metadataAllowedProperties}/>);


};
