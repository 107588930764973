import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          status: Yup.string()
                                                     .required('Required'),
                                          billingProviderId: Yup.string()
                                                                .required('Required'),
                                          billingProviderRecordId: Yup.string(),
                                      });
