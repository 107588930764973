import {
    deleteOrder,
    fetchOrder,
    saveOrder,
    updateOrder,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Order} from '../types';

export const useOrder = (orderId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, orderId ? orderId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof orderId === 'undefined') {
            throw new Error('orderId is required parameter');
        }
        dispatch(fetchOrder(orderId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Order) => {
        dispatch(saveOrder(values));
    };

    const updateRecord = (values: Order) => {
        if (typeof orderId === 'undefined') {
            throw new Error('orderId is required parameter');
        }

        dispatch(updateOrder({
                                 id: orderId,
                                 record: values,
                             }));
    };

    const deleteRecord = () => {
        if (typeof orderId === 'undefined') {
            throw new Error('orderId is required parameter');
        }
        dispatch(deleteOrder(orderId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
