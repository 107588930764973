export const listUsageRecords = `
   query listUsageRecords($filter: UsageRecordsFilterInput) {
       listUsageRecords(filter: $filter) {
           items {
                id
                username
                usageType
                value
                action
                recordId
                created
           }
       }
   }
`;

export const getUsageRecord = `
   query getUsageRecord($id:ID!) {
       getUsageRecord(id:$id) {
                id
                username
                usageType
                value
                action
                recordId
                created
       } 
}`;
