import {
    Table,
    Text,
} from '@mantine/core';
import {PlatformPropertyCardCopyButtonDecorator} from "@flexinet/ui-components";

export const DimensionsTable = (
    {
        dimensions
    }: {
        dimensions: {
            name: string,
            value: string,
            copyDecorator?: boolean
        }[]
    }) => {
    return (
        <Table striped>
            <Table.Tr>
                <Table.Th>
                    <Text fw={600}>Name:</Text>
                </Table.Th>
                <Table.Th>
                    <Text fw={600}>Value:</Text>
                </Table.Th>
            </Table.Tr>
            {dimensions.map((dimension, index) => (
                <Table.Tr key={index}>
                    <Table.Td>
                        <Text>{dimension.name}</Text>
                    </Table.Td>
                    <Table.Td>
                        {dimension.copyDecorator ? <PlatformPropertyCardCopyButtonDecorator value={dimension.value}/> :
                            <Text>{dimension.value}</Text>}
                    </Table.Td>
                </Table.Tr>
            ))}
        </Table>
    );
};
