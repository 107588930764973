export const createPrice = `
    mutation createPrice($input: CreatePriceInput!) {
        createPrice(input:$input) {
            id
            productId
            pricingModel
            ratePlanId
    }
}`;

export const updatePrice = `
    mutation updatePrice($input: UpdatePriceInput!) {
        updatePrice(input:$input){
            id
            productId
            pricingModel
            ratePlanId
        }
}`;

export const deletePrice = `
    mutation deletePrice($input: DeletePriceInput!) {
        deletePrice(input:$input) 
}`;
