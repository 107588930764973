export const createCustomer = `
    mutation createCustomer($input: CreateCustomerInput!) {
        createCustomer(input:$input) {
            id
            name
            description      
            status 
            billingInfo {
                billingProviderId
                billingProviderRecordId
                billingZip
            }
            created
            updated  
    }
}`;

export const updateCustomer = `
    mutation updateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input:$input){
            id
            name
            description
            status  
            billingInfo {
                billingProviderId
                billingProviderRecordId
                billingZip
            }
            created
            updated       
        }
}`;

export const deleteCustomer = `
    mutation deleteCustomer($input: DeleteCustomerInput!) {
        deleteCustomer(input:$input) 
}`;
