import {
    getServicePlan,
    listServicePlans
} from './queries';
import {
    createServicePlan,
    deleteServicePlan,
    updateServicePlan
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateServicePlan,
    DeleteServicePlan,
    GetServicePlan,
    ListServicePlans,
    ServicePlan,
    UpdateServicePlan
} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {plans} from "./service-plans.tsx";


const client = generateClient();


export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListServicePlans>>({
                                                                                  query: (listServicePlans),
                                                                                  variables: {filter: filter},
                                                                              });
        return [...plans, ...response.data?.listServicePlans.items];
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetServicePlan>>({
                                                                                query: (getServicePlan),
                                                                                variables: {id: id},
                                                                            });
        return response.data?.getServicePlan;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<CreateServicePlan>>({
                                                                                   query: (createServicePlan),
                                                                                   variables: {input: payload},
                                                                               });

        notifySuccess();
        return response.data?.createServicePlan;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (id: string, record: ServicePlan) => {

    console.debug('IN UPDATE REGISTRAR', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateServicePlan>>({
                                                                                   query: (updateServicePlan),
                                                                                   variables: {input: record},
                                                                               });

        notifySuccess();
        return response.data?.updateServicePlan;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteServicePlan>>({
                                                                                   query: (deleteServicePlan),
                                                                                   variables: {input: {id: id}},
                                                                               });
        notifySuccess();
        return response.data?.deleteServicePlan;
    } catch (e) {
        notifyError(e)
    }
};
