import {
    SimplePageContentWrapper,
    SimplePageLayout
} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {RecordsTable} from "../tables/RecordsTable.tsx";

export const IndexPage = () => {

    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
            pageNavigation={<IndexPageNavigation/>}
        >

            <SimplePageContentWrapper><RecordsTable/></SimplePageContentWrapper>
            {/*<ServicePlansComponent plans={plans}/>*/}

        </SimplePageLayout>
    );
};
