import {RecordForm} from '../forms/RecordForm';


import {useUsageRecord} from '../hooks/useUsageRecord.ts';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useUsageRecord();

    return (
        <FormPageLayout
            title='Add Usage Record'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
