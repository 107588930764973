import {
    IPaymentProfileTableColumn,
    PaymentProfile
} from "../types.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {RowSelectionDataCell} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {PaymentProfileStatusBadge} from "../components/PaymentProfileStatusBadge.tsx";
import {usePaymentProfilesSelection} from "./usePaymentProfilesSelection.ts";

export const useRecordsTableColumns = (billingAccountId: string) => {
    let columns: IPaymentProfileTableColumn[] = [];

    const {allowedActions} = useVerifiedPermissions()

    const {
        handleRowSelection,
    } = usePaymentProfilesSelection(billingAccountId);

    if (allowedActions.includes(rbacActions.DeleteBillingAccount)) {

        columns.push({
                         id: 'select',
                         title: '',
                         render: item => <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
                     },
        );
    }

    const additional = [
        {
            title: I18n.get('Payment Profile Name'),
            width: '1fr',
            render: (item: PaymentProfile) => <NavLink
                to={`/billing-accounts/${billingAccountId}/payment-profiles/${item.id}`}>{item.name}</NavLink>,
        },
        {
            title: I18n.get('Status'),
            width: '1fr',
            render: (item: PaymentProfile) => <PaymentProfileStatusBadge status={item.status}/>,
        },
        {
            title: I18n.get('Created'),
            width: '1fr',
            render: (item: PaymentProfile) => formatTimestamp(item.created as string),
        },
    ];

    columns = columns.concat(additional);


    return {
        columns,
    }
}