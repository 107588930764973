import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo,
    AiOutlineLogin
} from "react-icons/ai";


export const showErrorNotifications = (e: any) => {

    console.debug('handleAppSyncResponseException', e)

    if (typeof e.errors !== 'undefined' && e.errors.length > 0) {
        e.errors.forEach((error: { message: string; }) => {
            showErrorNotification(error.message);
        })
    }
}


export const notifySuccess = (message = 'Record saved successfully') => {
    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'teal',
                     })
}

export const showErrorNotification = (message = 'Error performing the operation') => {
    showNotification({
                         title: 'Error',
                         icon: <AiOutlineExclamation/>,
                         message: message,
                         color: 'red',
                     })

    const noCurrentUserMessage = 'no current user'
    if (message.toLowerCase() === noCurrentUserMessage) {
        notifySessionExpired();
    }

}

export const notifyError = (e: any) => {

    console.trace(e);

    if (typeof e.errors !== 'undefined' && Array.isArray(e.errors)) {
        e.errors.forEach((error: { message: string; }) => {
            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: error.message,
                                 color: 'red',
                             });
        })
        return;
    }

    const message = e.message ? e.message : 'Error performing the operation'

    showNotification({
                         title: 'Error',
                         icon: <AiOutlineExclamation/>,
                         message: message,
                         color: 'red',
                     })

    const noCurrentUserMessage = 'no current user'
    const noFederatedJwtMessage = 'No federated jwt'
    if (message.toLowerCase() === noCurrentUserMessage || message.toLowerCase() === noFederatedJwtMessage) {
        notifySessionExpired();
    }

}

export const notifySessionExpired = (message = 'Redirecting to login page... Please, wait!') => {

    showNotification({
                         title: 'Session Expired',
                         icon: <AiOutlineLogin/>,
                         message: message,
                         color: 'blue',
                     });

    setTimeout(() => {
        window.location.reload();
    }, 3000);

};


// notify access denied
export const notifyAccessDenied = (message = 'Access denied') => {
    showNotification({
                         title: 'Access Denied',
                         icon: <AiOutlineExclamation/>,
                         message: message,
                         color: 'red',
                     })
}