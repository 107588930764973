import {
    getBillingProvider,
    listBillingProviders
} from './queries';
import {
    createBillingProvider,
    deleteBillingProvider,
    updateBillingProvider,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    BillingProvider,
    CreateBillingProvider,
    DeleteBillingProvider,
    GetBillingProvider,
    ListBillingProviders,
    UpdateBillingProvider
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";
import {ListBillingProvidersResult} from "../schemas";


const client = generateClient();


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListBillingProviders>>({
                                                                                      query: listBillingProviders,
                                                                                      variables: {filter: filter},
                                                                                  });

        const parsed = ListBillingProvidersResult.parse(response)
        return parsed.data.listBillingProviders.items;

        // return response.data?.listBillingProviders.items;
    } catch (e) {
        console.trace(e);
        notifyError(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetBillingProvider>>({
                                                                                    query: getBillingProvider,
                                                                                    variables: {id: id},
                                                                                });
        return response.data?.getBillingProvider;
    } catch (e) {
        console.trace(e);
        notifyError(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateBillingProvider>>({
                                                                                       query: createBillingProvider,
                                                                                       variables: {input: payload},
                                                                                   });
        console.debug('in the service response', response);
        notifySuccess('Billing Provider Added');
        return response.data?.createBillingProvider;
    } catch (e) {
        console.trace(e);
        notifyError(e);
    }
};

export const update = async (id: string, record: BillingProvider) => {

    console.debug('IN UPDATE', id, record);

    const {
        created,
        updated,
        ...rest
    } = record

    try {
        const response = await client.graphql<GraphQLQuery<UpdateBillingProvider>>({
                                                                                       query: (updateBillingProvider),
                                                                                       variables: {input: rest},
                                                                                   });

        notifySuccess('Billing Provider Updated');
        return response.data?.updateBillingProvider;
    } catch (e) {
        console.trace(e);
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteBillingProvider>>({
                                                                                       query: (deleteBillingProvider),
                                                                                       variables: {input: {id: id}},
                                                                                   });
        notifySuccess('Billing Provider Deleted');
        return response.data?.deleteBillingProvider;
    } catch (e) {
        console.trace(e);
        notifyError(e);
    }
};
