export const listPaymentProfiles = `
   query listPaymentProfiles {
       listPaymentProfiles {
           items {
              id
              name
              description
              billingAccountId
              status
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getPaymentProfile = `
   query getPaymentProfile($id:ID!) {
       getPaymentProfile(id:$id) {
         id
         name
         description 
         billingAccountId
         status
         created
         createdByUsername
         updated
       } 
}`;
