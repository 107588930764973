import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Customer} from '../types';
import {CustomerStatusBadge} from "../components/CustomerStatusBadge.tsx";

export const columns = [
    {
        title: I18n.get('Customer Name'),
        width: '1fr',
        render: (item: Customer) => <NavLink to={`/customers/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Customer) => <CustomerStatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Customer) => formatTimestamp(item.created as string),
    },
];
