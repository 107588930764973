import {
    configureStore,
    Store
} from '@reduxjs/toolkit';
import {ProductsReducer} from "../features/products/reducers";
import {MeteringRecordsReducer} from "../features/metering-records/reducers";
import {CustomersReducer} from "../features/customers/reducers";
import {OrdersReducer} from "../features/orders/reducers";
import {RatePlansReducer} from "../features/rate-plans/reducers";
import {SubscriptionsReducer} from "../features/subscriptions/reducers";
import {BillingProvidersReducer} from "../features/billing-providers/reducers";
import {MetersReducer} from "../features/meters/reducers";
import {ServicePlansReducer} from "../features/service-plans/reducers";
import {PricesReducer} from "../features/prices/reducers";
import {InvoicesReducer} from "../features/invoices/reducers";
import {UsageRecordsReducer} from "../features/usage-records/reducers";
import {BillingAccountsReducer} from "../features/billing-accounts/reducers";
import {PaymentProfilesReducer} from "../features/payment-profiles/reducers";


const reducers = {
    customers: CustomersReducer,
    invoices: InvoicesReducer,
    meters: MetersReducer,
    meteringRecords: MeteringRecordsReducer,
    orders: OrdersReducer,
    prices: PricesReducer,
    products: ProductsReducer,
    ratePlans: RatePlansReducer,
    servicePlans: ServicePlansReducer,
    subscriptions: SubscriptionsReducer,
    billingProviders: BillingProvidersReducer,
    usageRecords: UsageRecordsReducer,
    billingAccounts: BillingAccountsReducer,
    paymentProfiles: PaymentProfilesReducer,
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch