import {
    useEffect,
    useState,
} from 'react';

import {Autocomplete,} from '@mantine/core';

import {allComponentsSelector} from '../selectors';

import {useRatePlans} from '../hooks/useRatePlans.ts';
import {RatePlan} from '../types';
import {FieldProps} from 'formik';
import {useAppSelector} from "../../../hooks/useTypedReactRedux.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

export const RatePlansAutocomplete = ({
                                          field: {
                                              name,
                                              value: fieldValue,
                                          },
                                          form: {setFieldValue},
                                          ...rest
                                      }: FieldProps) => {

    const {user} = useAuth()

    if (!user) {
        throw new Error('user is required parameter')
    }

    const {fetchRecords} = useRatePlans();

    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const records = useAppSelector((state) => allComponentsSelector(state));

    useEffect(() => {
        fetchRecords();
    }, []);

    const data = records.map((record: RatePlan) => {
        return {
            ...record,
            label: record.name,
            value: record.id,
        };
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        const found = records.find((item: RatePlan) => item.id === fieldValue);
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'name')) {
            setValue(found.name);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const {id} = data.find((item) => item.name === value) || {};

        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select RatePlan'
            {...rest}
        />
    );
};