import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useOrders} from '../hooks/useOrders.ts';
import {useOrdersSelection} from "../hooks/useOrdersSelection.ts";

export const DeleteRecordButton = () => {

    const {deleteRecords} = useOrders();

    const {
        selection,
        handleClearSelection,
    } = useOrdersSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
