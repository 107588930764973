import {
    deleteBillingAccount,
    fetchBillingAccount,
    saveBillingAccount,
    updateBillingAccount,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {BillingAccount} from '../types';

export const useBillingAccount = (billingAccountId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, billingAccountId ? billingAccountId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof billingAccountId === 'undefined') {
            throw new Error('billingAccountId is required parameter');
        }
        dispatch(fetchBillingAccount(billingAccountId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: BillingAccount) => {
        dispatch(saveBillingAccount(values));
    };

    const updateRecord = (values: BillingAccount) => {
        if (typeof billingAccountId === 'undefined') {
            throw new Error('billingAccountId is required parameter');
        }

        dispatch(updateBillingAccount({
                                          id: billingAccountId,
                                          record: values,
                                      }));
    };

    const deleteRecord = () => {
        if (typeof billingAccountId === 'undefined') {
            throw new Error('billingAccountId is required parameter');
        }
        dispatch(deleteBillingAccount(billingAccountId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
