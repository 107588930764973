import {Avatar} from '@mantine/core';
import {ServicePlan} from '../types';
import {AiOutlineTransaction} from "react-icons/ai";

export const RecordLogo = ({
                               record,
                               size = 'sm',
                           }: {
    record: ServicePlan,
    size?: string
}) => {
    const alt = record.name;

    return (
        <Avatar alt={alt} size={size}>
            <AiOutlineTransaction/>
        </Avatar>
    );
};

