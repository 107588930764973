import {ITableColumn} from '../../types';
import {ReactNode} from 'react';
import {Product} from "../products/types.ts";

export type ServicePlanDimension = { name: string, value: string }

export enum ServicePlanStatus {
    'Active' = 'Active',
    'Deprecated' = 'Deprecated',
    'Draft' = 'Draft'
}


export type ServicePlan = {
    id: string,
    name: string,
    status: ServicePlanStatus,
    description?: string,
    current: boolean,
    baseFee: string,
    included: string,
    price: string,
    features: {
        title: string,
        supported: boolean
    }[],
    freeTrial: boolean
    productIds: string[],
    products?: {
        items: Product[],
        nextToken?: string
    }
    createdByUsername?: string,
    createdBy?: { username: string, givenName: string, familyName: string },
    created?: string,
    updated?: string,

    // experimental
    trialLength?: number,
    setupFee?: number,
    billingPeriod?: number,
    subscriptionTermLength?: number
    totalBillingCycles?: number
    autoRenew?: boolean,
    pricingModel?: string,
}


export interface IServicePlanTableColumn extends ITableColumn {
    render?: (item: ServicePlan) => ReactNode;
}

export type ListServicePlans = {
    listServicePlans: { items: ServicePlan[] }
}

export type GetServicePlan = {
    getServicePlan: ServicePlan
}

export type CreateServicePlan = {
    createServicePlan: ServicePlan
}

export type UpdateServicePlan = {
    updateServicePlan: ServicePlan
}

export type DeleteServicePlan = {
    deleteServicePlan: string
}
