export const createMeter = `
    mutation createMeter($input: CreateMeterInput!) {
        createMeter(input:$input) {
            id
            name
            apiKey
            type
            status
            dimensions {
                name
                apiKey
            }
            created
            updated  
    }
}`;

export const updateMeter = `
    mutation updateMeter($input: UpdateMeterInput!) {
        updateMeter(input:$input){
            id
            name
            apiKey
            type
            status
            dimensions {
                name
                apiKey
            }
            created
            updated       
        }
}`;

export const deleteMeter = `
    mutation deleteMeter($input: DeleteMeterInput!) {
        deleteMeter(input:$input) 
}`;
