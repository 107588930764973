export const createUsageRecord = `
    mutation createUsageRecord($input: CreateUsageRecordInput!) {
        createUsageRecord(input:$input) {
            id
            username
            usageType
            value
            action
            recordId
            created
    }
}`;

export const updateUsageRecord = `
    mutation updateUsageRecord($input: UpdateUsageRecordInput!) {
        updateUsageRecord(input:$input){
            id
            username
            usageType
            value
            action
            recordId
            created
        }
}`;

export const deleteUsageRecord = `
    mutation deleteUsageRecord($input: DeleteUsageRecordInput!) {
        deleteUsageRecord(input:$input) 
}`;
