import {useNavigate} from 'react-router-dom';
import {useToggle} from '@mantine/hooks';


import {
    AiOutlineEdit,
    AiOutlineProfile
} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {
    FlexiButton,
    FlexiCancelButton
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();

    const {allowedActions} = useVerifiedPermissions()

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (allowedActions.includes(rbacActions.UpdatePrice)) {

        if (mode === 'edit') {
            tools.push(<FlexiButton key={'profile'}
                                    leftSection={<IconContext.Provider value={{size: '24px'}}>
                                        <AiOutlineProfile/></IconContext.Provider>}
                                    onClick={() => toggle()}>Profile</FlexiButton>);
        } else {
            tools.push(<FlexiButton leftSection={<AiOutlineEdit/>} variant={'filled'} key={'edit'}
                                    onClick={() => toggle()}>Edit</FlexiButton>);
        }

    }

    tools.push(<FlexiCancelButton key={'close'}
                                  onClick={() => navigate(`/prices`)}/>);


    return {
        mode,
        tools,
    };

};
