import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          productId: Yup.string()
                                                        .required('Required'),
                                          pricingModel: Yup.string()
                                                           .required('Required'),
                                          ratePlanId: Yup.string()
                                                         .required('Required'),
                                      });
