import {Amplify} from 'aws-amplify';

import {stage} from '../config/stage';
import {ConsoleLogger} from 'aws-amplify/utils';

// get config
import {config} from '../config/amplify';

Amplify.configure(config);

stage === 'ide'
    ? (ConsoleLogger.LOG_LEVEL = 'DEBUG')
    : (ConsoleLogger.LOG_LEVEL = 'INFO');

declare let window: any;

window.LOG_LEVEL = 'DEBUG';
