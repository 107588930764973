import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/RatePlansService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {RatePlan} from '../types';

const entityKey = 'ratePlans';

interface RatePlansState {
    byId: { [key: string]: RatePlan },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as RatePlansState;


const normalizeRecord = (record: RatePlan) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchRatePlans = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: RatePlan) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchRatePlan = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveRatePlan = createAsyncThunk(
    `${entityKey}/add`,
    (payload: RatePlan) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateRatePlan = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: RatePlan }) => {

        const filtered = {
            id: record.id,
            name: record.name,
            chargeTiers: record.chargeTiers
        } as RatePlan;
        
        return update(id, filtered)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteRatePlan = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeRatePlan = createAsyncThunk(
    `${entityKey}/store`,
    (payload: RatePlan) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchRatePlans, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchRatePlan);

                                            // SAVE ONE
                                            saveOneCase(builder, saveRatePlan);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateRatePlan);

                                            updateOneCase(builder, storeRatePlan);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteRatePlan);
                                        },
                                    });

export default componentsSlice.reducer;
