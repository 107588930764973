export const listInvoices = `
   query listInvoices {
       listInvoices {
           items {
              id
              customerId
           }
       }
   }
`;

export const getInvoice = `
   query getInvoice($id:ID!) {
       getInvoice(id:$id) {
         id
         customerId
       } 
}`;
