import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {MeteringRecord} from '../types';
import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<MeteringRecord, 'id'>,
    handleSubmit: (values: MeteringRecord) => void
}) => {

    const navigate = useNavigate()


    const onSubmit = (values: MeteringRecord) => {
        handleSubmit(values);
    };

    const onCancel = () => {
        navigate(`/metering-records`);
    };

    let initialValues = {} as MeteringRecord;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent values={rest.values}/>
                </FormWithButtons>)}
        </Formik>
    );
};
