import {FormikSelect} from '@flexinet/ui-components';
import {MeterDimension} from '../types';
import {FieldProps} from 'formik';
import {SelectProps} from '@mantine/core';
import {useMeter} from "../hooks/useMeter.ts";
import {useEffect} from "react";


export const MeterDimensionsDropdown = (props: FieldProps & SelectProps & { meterId: string }) => {

    const {
        record,
        fetchRecord
    } = useMeter(props.meterId);

    useEffect(() => {
        fetchRecord();
    }, [props.meterId]);

    if (!record) {
        return null;
    }

    let selectOptions: { value: string; label: string }[] = [];
    if (record.dimensions) {
        selectOptions = record.dimensions.map((d: MeterDimension) => {
            return {
                value: d.apiKey,
                label: d.name,
            };
        });
    }

    return <FormikSelect {...props} data={selectOptions}/>;
};