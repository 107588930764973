import {I18n} from '@aws-amplify/core';

import {SimplePageHeader,} from '@flexinet/ui-components';
import {AiOutlineBank} from 'react-icons/ai'
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ReloadRecordsButton key='reload'/>];

    if (allowedActions.includes(rbacActions.DeleteInvoiceItem)) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes(rbacActions.CreateInvoiceItem)) {
        tools.push(<AddRecordButton key='add'/>);
    }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Invoice Items')}
            icon={<AiOutlineBank/>}
            tools={useTools()}
        />
    );
};

