import {Field} from 'formik';

import {
    FormikDateTimePicker,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Box} from "@mantine/core";
import {UsageRecord} from "../types.ts";


export const FormComponent = ({values}: { values: UsageRecord }) => {

    console.debug('form component values', values)

    return (
        <Box>
            <PlatformFieldset legend={'Usage Details'}>
                <Field
                    name='username'
                    label='Username'
                    description='Please select the username'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='value'
                    label='Value'
                    description='Please enter the usage value'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='action'
                    label='Action'
                    description='Please enter the action'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Record'}>

                <Field
                    name='recordType'
                    label='Record Type'
                    description='Please select the record type'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />


                <Field
                    name='recordId'
                    label='Record ID'
                    description='Please enter the record id'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Created'}>

                <Field
                    name='created'
                    label='Created'
                    description='Please enter the creation time'
                    component={FormikDateTimePicker}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

        </Box>
    );
};
