import {
    ReactElement,
    useEffect,
} from 'react';

import {
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useUsageRecords} from '../hooks/useUsageRecords.ts';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useUsageRecordsSelection} from '../hooks/useUsageRecordsSelection.ts';
import {IUsageRecordTableColumn,} from '../types';
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {useUsageRecordsPaging} from "../hooks/useUsageRecordsPaging.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()

    const {
        user,
    } = useAuth()


    if (!user) {
        throw new Error('user is required parameter')
    }


    const {
        columns,
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
    } = useUsageRecords();

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = useUsageRecordsPaging();

    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useUsageRecordsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IUsageRecordTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeleteUsageRecord)) {

            cols.push(
                {
                    id: 'select',
                    title: '',
                    render: item => <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
                },
            );
        }

        cols = cols.concat(columns);

        return cols;

    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
