import {I18n} from '@aws-amplify/core';

import {SimplePageHeader,} from '@flexinet/ui-components';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {AddTestRecordButton} from "../buttons/AddTestRecordButton.tsx";

const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [];


    tools.push(<ReloadRecordsButton key='reload'/>);

    if (allowedActions.includes(rbacActions.DeleteUsageRecord)) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    tools.push(<AddTestRecordButton key='add-test-record'/>);

    if (allowedActions.includes(rbacActions.CreateUsageRecord)) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Usage Records')}
            tools={useTools()}
        />
    );
};
