import {
    ReactElement,
    useEffect,
} from 'react';

import {
    SimpleGrid,
    Table,
} from '@mantine/core';

import {usePaymentProfiles} from '../hooks/usePaymentProfiles.ts';

import {
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {usePaymentProfilesSelection} from '../hooks/usePaymentProfilesSelection.ts';
import {useAuth} from "../../../hooks/useAuth.ts";
import {usePaymentProfilesPaging} from "../hooks/usePaymentProfilesPaging.ts";
import {useRecordsTableColumns} from "../hooks/useRecordsTableColumns.tsx";
import {useParams} from "react-router-dom";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {id: billingAccountId} = useParams()

    if (!billingAccountId) {
        throw new Error('billingAccountId is required parameter')
    }

    const {
        user,
    } = useAuth()


    if (!user) {
        throw new Error('user is required parameter')
    }

    const {
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
    } = usePaymentProfiles(billingAccountId);

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = usePaymentProfilesPaging(billingAccountId);

    const {
        selection,
        handleSelectAllRows,
    } = usePaymentProfilesSelection(billingAccountId);

    const {
        columns,
    } = useRecordsTableColumns(billingAccountId);

    useEffect(() => {
        fetchRecords();
    }, []);


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={columns as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={columns as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
