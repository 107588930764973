import {Field} from 'formik';

import {
    FormikNumberInput,
    FormikSelect,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Stack} from "@mantine/core";
import {FormikList} from "../../../components/FormikList";


export const FormComponent = () => {

    return (
        <Stack gap={'lg'}>
            <PlatformFieldset legend={'Rate Plan'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the ratePlans name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='billableUnit'
                    label='Billable Unit'
                    description='Please enter the billable unit name'
                    component={FormikSelect}
                    data={[{
                        id: '1',
                        label: 'Domain Name'
                    }, {
                        id: '2',
                        label: 'Registrar'
                    }, {
                        id: '3',
                        label: 'User'
                    }]}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='billablePeriod'
                    label='Billable Period'
                    description='Please enter the billable period'
                    component={FormikSelect}
                    data={[{
                        id: '1',
                        label: 'Month'
                    }, {
                        id: '2',
                        label: 'Quarter'
                    }, {
                        id: '3',
                        label: 'Year'
                    }]}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Tiers'}>

                <Field
                    name='chargeTiers'
                    label='Charge Tiers'
                    description='Please enter Charge Tiers'
                    component={FormikList}
                    getRowElements={() => {
                        return [{
                            name: 'lowerBand',
                            placeholder: 'Lower Band',
                            component: FormikTextInput,
                        }, {
                            name: 'upperBand',
                            placeholder: 'Upper Band',
                            component: FormikTextInput,
                        }, {
                            name: 'rate',
                            placeholder: 'Rate',
                            component: FormikNumberInput,
                        }]
                    }}
                    my={'xl'}
                />

            </PlatformFieldset>

        </Stack>
    );
};
