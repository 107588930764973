import {stage} from '../stage';

export type ConfigShape = {
    endpoint: string,
    region: string,
    apiKey?: string,
}

export type ConfigCollectionShape = {
    [key: string]: ConfigShape
}

const fulfillmentServiceAPI = {
    prod: {
        endpoint: 'https://yqx3ceuew8.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
    },
    test: {
        endpoint: 'https://bdfr4wta2c.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
    },
    dev: {
        endpoint: 'https://ots0aj34o6.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
    },
    ide: {
        endpoint: 'https://ots0aj34o6.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
    },
} as ConfigCollectionShape;


const apiEndpoints = {
    'FLEXI_FULFILLMENT_API': {
        endpoint: fulfillmentServiceAPI[stage].endpoint,
        region: fulfillmentServiceAPI[stage].region,
    },
};

export const config = apiEndpoints;
