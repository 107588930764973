import {AppLayout} from "../layout/AppLayout.tsx";
import {PublicHomePage} from "../features/home/pages/PublicHomePage.tsx";
import {SignOutPage} from "../features/home/pages/SignOutPage.tsx";
import {RequireAuth} from "../utils/RequireAuth.tsx";
import {IndexPage as DashboardPage} from "../features/dashboard/pages/IndexPage.tsx";
import {ProductsLayout} from "../features/products/layout.tsx";
import {CustomersLayout} from "../features/customers/layout.tsx";
import {MeteringRecordsLayout} from "../features/metering-records/layout.tsx";
import {OrdersLayout} from "../features/orders/layout.tsx";
import {RatePlansLayout} from "../features/rate-plans/layout.tsx";
import {BillingProvidersLayout} from "../features/billing-providers/layout.tsx";
import {SubscriptionsLayout} from "../features/subscriptions/layout.tsx";
import {MetersLayout} from "../features/meters/layout.tsx";
import {ServicePlansLayout} from "../features/service-plans/layout.tsx";
import {PricesLayout} from "../features/prices/layout.tsx";
import {InvoicesLayout} from "../features/invoices/layout.tsx";
import {UsageRecordsLayout} from "../features/usage-records/layout.tsx";
import {BillingAccountsLayout} from "../features/billing-accounts/layout.tsx";


export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'products/*',
        element: <ProductsLayout/>
    }, {
        path: 'customers/*',
        element: <CustomersLayout/>
    }, {
        path: 'prices/*',
        element: <PricesLayout/>
    }, {
        path: 'metering-records/*',
        element: <MeteringRecordsLayout/>
    }, {
        path: 'usage-records/*',
        element: <UsageRecordsLayout/>
    }, {
        path: 'orders/*',
        element: <OrdersLayout/>
    }, {
        path: 'rate-plans/*',
        element: <RatePlansLayout/>
    }, {
        path: 'billing-providers/*',
        element: <BillingProvidersLayout/>
    }, {
        path: 'subscriptions/*',
        element: <SubscriptionsLayout/>
    }, {
        path: 'service-plans/*',
        element: <ServicePlansLayout/>
    }, {
        path: 'meters/*',
        element: <MetersLayout/>
    }, {
        path: 'invoices/*',
        element: <InvoicesLayout/>
    }, {
        path: 'billing-accounts/*',
        element: <BillingAccountsLayout/>
    }]
}]