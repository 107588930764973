import {ServicePlan,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {StatusBadge} from "./StatusBadge.tsx";


export const InformationPropertyCard = ({record}: { record: ServicePlan }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Status',
            value: <StatusBadge status={record.status}/>,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};
