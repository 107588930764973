import {
    deleteRatePlan,
    fetchRatePlan,
    saveRatePlan,
    updateRatePlan,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {RatePlan} from '../types';

export const useRatePlan = (ratePlansId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, ratePlansId ? ratePlansId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof ratePlansId === 'undefined') {
            throw new Error('ratePlansId is required parameter');
        }
        dispatch(fetchRatePlan(ratePlansId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: RatePlan) => {
        dispatch(saveRatePlan(values));
    };

    const updateRecord = (values: RatePlan) => {
        if (typeof ratePlansId === 'undefined') {
            throw new Error('ratePlansId is required parameter');
        }

        dispatch(updateRatePlan({
                                    id: ratePlansId,
                                    record: values,
                                }));
    };

    const deleteRecord = () => {
        if (typeof ratePlansId === 'undefined') {
            throw new Error('ratePlansId is required parameter');
        }
        dispatch(deleteRatePlan(ratePlansId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
