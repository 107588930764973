import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineClockCircle,
    AiOutlineControl,
    AiOutlineDollar,
    AiOutlineDollarCircle,
    AiOutlineFile,
    AiOutlineHome,
    AiOutlineNumber,
    AiOutlineTransaction
} from 'react-icons/ai'

import {TfiRulerAlt} from "react-icons/tfi";


import {IconContext} from "react-icons";
import {MdOutlineBusinessCenter} from "react-icons/md";
import {OrderIcon} from "../../../features/orders/images/OrderIcon.tsx";
import {SubscriptionIcon} from "../../../features/subscriptions/images/SubscriptionIcon.tsx";
import {RatePlanIcon} from "../../../features/rate-plans/images/RatePlanIcon.tsx";
import {rbacActions} from "../../../config/rbac/actions.ts";


export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: rbacActions.ViewDashboard,
        },
        {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Pricing',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineDollar/></IconContext.Provider>,
            rbac: rbacActions.ListServicePlans,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/service-plans',
                    label: 'Service Plans',
                    icon: <AiOutlineTransaction size={'1.6em'}/>,
                    rbac: rbacActions.ListServicePlans,
                },
                {
                    id: uuidv4(),
                    link: '/products',
                    label: 'Products',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineAppstore/></IconContext.Provider>,
                    rbac: rbacActions.ListProducts,
                },
                {
                    id: uuidv4(),
                    link: '/prices',
                    label: 'Prices',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineDollarCircle/></IconContext.Provider>,
                    rbac: rbacActions.ListPrices,
                },
                {
                    id: uuidv4(),
                    link: '/rate-plans',
                    label: 'Rate Plans',
                    icon: <RatePlanIcon/>,
                    rbac: rbacActions.ListRatePlans,
                },
            ]
        },
        {
            id: uuidv4(),
            link: '/meters',
            label: 'Metering',
            icon: <IconContext.Provider value={{size: '2em'}}><TfiRulerAlt/></IconContext.Provider>,
            rbac: rbacActions.ListMeters,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/meters',
                    label: 'Meters',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><TfiRulerAlt/></IconContext.Provider>,
                    rbac: rbacActions.ListMeters,
                },
                {
                    id: uuidv4(),
                    link: '/metering-records',
                    label: 'Metering Records',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineClockCircle/></IconContext.Provider>,
                    rbac: rbacActions.ListMeteringRecords,
                },
                {
                    id: uuidv4(),
                    link: '/usage-records',
                    label: 'Usage Records',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineFile/></IconContext.Provider>,
                    rbac: rbacActions.ListUsageRecords,
                },
            ]
        },
        {
            id: uuidv4(),
            link: '/customers',
            label: 'Billing',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineNumber/></IconContext.Provider>,
            rbac: rbacActions.ListCustomers,
            defaultOpened: true,
            links: [
                {
                    id: uuidv4(),
                    link: '/billing-accounts',
                    label: 'Billing Accounts',
                    icon: <IconContext.Provider
                        value={{size: '1.6em'}}><MdOutlineBusinessCenter/></IconContext.Provider>,
                    rbac: rbacActions.ListBillingAccounts,
                },
                {
                    id: uuidv4(),
                    link: '/customers',
                    label: 'Customers',
                    icon: <IconContext.Provider
                        value={{size: '1.6em'}}><MdOutlineBusinessCenter/></IconContext.Provider>,
                    rbac: rbacActions.ListCustomers,
                },
                {
                    id: uuidv4(),
                    link: '/invoices',
                    label: 'Invoices',
                    icon: <OrderIcon size={'1.6em'}/>,
                    rbac: rbacActions.ListInvoices,
                },
                {
                    id: uuidv4(),
                    link: '/orders',
                    label: 'Orders',
                    icon: <OrderIcon size={'1.6em'}/>,
                    rbac: rbacActions.ListOrders,
                },
                {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Subscriptions',
                    icon: <SubscriptionIcon size={'1.6em'}/>,
                    rbac: rbacActions.ListSubscriptions,
                },
                {
                    id: uuidv4(),
                    link: '/billing-providers',
                    label: 'Billing Providers',
                    icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineControl/></IconContext.Provider>,
                    rbac: rbacActions.ListBillingProviders,
                },
            ]
        }] as PlatformNavbarMenuItem[];

};

