import {Badge} from "@mantine/core";

export const StatusBadge = ({status}: { status: string }) => {
    const colors = {
        'Active': '#007bff',
        'Deprecated': '#6c757d',
        'Draft': '#ffc107',
    } as { [key: string]: string }

    return <Badge color={colors[status]}>{status}</Badge>;
};

