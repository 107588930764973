import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {usePaymentProfiles} from '../hooks/usePaymentProfiles.ts';
import {useParams} from "react-router-dom";

export const ReloadRecordsButton = () => {


    const {id: billingAccountId} = useParams()

    if (typeof billingAccountId === 'undefined') {
        throw new Error('billingAccountId is required parameter')
    }

    const {fetchRecords} = usePaymentProfiles(billingAccountId);

    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
