import {Badge,} from '@mantine/core';
import {BillingProvider,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const Profile = ({record}: { record: BillingProvider }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 6,
        }, {
            label: 'Billing Provider Key',
            value: record.key as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Status',
            value: record.status && record.status === 'Enabled' ? <Badge color={'green'}>Enabled</Badge> :
                <Badge color={'red'}>Disabled</Badge>,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBillingProvider',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }, {
            label: 'Updated By',
            value: typeof record.updatedBy !== 'undefined' && record.updatedBy !== null ? [record.updatedBy.givenName, record.updatedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateBillingProvider',
            span: 3,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};
