import {Field} from 'formik';
import {Container} from "@mantine/core";
import {
    FormikRadioGroup,
    FormikTextInput,
    PlatformFieldset
} from "@flexinet/ui-components";
import {MeterType} from "../types.ts";
import {FormikList} from "../../../components/FormikList";


export const FormComponent = () => {

    return (
        <Container>
            <PlatformFieldset legend={'Meter'}>
                <Field
                    name='name'
                    label='Display Name'
                    description='Please enter name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='apiKey'
                    label='API Key'
                    description='Please enter API key'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='type'
                    label='Type'
                    description='Please enter type'
                    component={FormikRadioGroup}
                    data={[{
                        label: MeterType.Sum,
                        value: MeterType.Sum
                    }, {
                        label: 'Unique Count',
                        value: MeterType.UniqueCount
                    }, {
                        label: MeterType.Duration,
                        value: MeterType.Duration
                    }, {
                        label: MeterType.Max,
                        value: MeterType.Max
                    }, {
                        label: 'Max Persist',
                        value: MeterType.MaxPersist
                    }, {
                        label: 'Running Total',
                        value: MeterType.RunningTotal
                    }]}
                    withAsterisk
                    my={'xl'}
                />
            </PlatformFieldset>


            <PlatformFieldset legend={'Meter Dimensions'}>

                <Field
                    name='dimensions'
                    label='Dimensions'
                    description='Please enter dimensions'
                    component={FormikList}
                    getRowElements={() => [{
                        name: 'name',
                        placeholder: 'Display Name',
                        component: FormikTextInput,
                    }, {
                        name: 'apiKey',
                        placeholder: 'API Key',
                        component: FormikTextInput,
                    }]}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>

                <Field
                    name='status'
                    label='Status'
                    description='Please enter status'
                    component={FormikRadioGroup}
                    data={[{
                        value: 'Draft',
                        label: 'Draft',
                    }, {
                        value: 'Active',
                        label: 'Active',
                    }, {
                        value: 'Deprecated',
                        label: 'Deprecated',
                    }]}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

        </Container>
    );
};
