import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Meter} from '../types';
import {StatusBadge} from "../components/StatusBadge.tsx";


export const columns = [
    {
        title: I18n.get('Meter'),
        width: '1fr',
        render: (item: Meter) => <NavLink to={`/meters/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('API key'),
        width: '1fr',
        render: (item: Meter) => item.apiKey,
    },
    {
        title: I18n.get('Type'),
        width: '1fr',
        render: (item: Meter) => item.type,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Meter) => <StatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Dimensions'),
        width: '1fr',
        render: (item: Meter) => item.dimensions ? item.dimensions.length : '-',
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Meter) => formatTimestamp(item.created as string),
    },
];
