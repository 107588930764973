import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          type: Yup.string()
                                                   .required('Required'),
                                          status: Yup.string()
                                                     .required('Required'),
                                          dimensions: Yup.array()
                                                         .of(Yup.object()
                                                                .shape({
                                                                           name: Yup.string()
                                                                                    .required('Required'),
                                                                       })),
                                      });
