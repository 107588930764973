export const createPaymentProfile = `
    mutation createPaymentProfile($input: CreatePaymentProfileInput!) {
        createPaymentProfile(input:$input) {
            id
            name
            description      
            status 
            billingAccountId
            created
            createdByUsername
            updated  
    }
}`;

export const updatePaymentProfile = `
    mutation updatePaymentProfile($input: UpdatePaymentProfileInput!) {
        updatePaymentProfile(input:$input){
            id
            name
            description
            status  
            billingAccountId
            created
            createdByUsername
            updated       
        }
}`;

export const deletePaymentProfile = `
    mutation deletePaymentProfile($input: DeletePaymentProfileInput!) {
        deletePaymentProfile(input:$input) 
}`;
