import React from 'react'
import ReactDOM from 'react-dom/client'
import {App} from './App'

import "@fontsource/inter"


// import './assets/sass/index.scss';
import './init/amplify';
// import './init/cloudwatch-rum';
import {Provider} from 'react-redux';
import {store} from "./store/index";

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <React.StrictMode>
                <Provider store={store}>
                    <App/>
                </Provider>
            </React.StrictMode>,
        )
