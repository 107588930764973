import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {MeteringRecord} from '../types';

export const columns = [
    {
        title: I18n.get('ID'),
        width: '1fr',
        render: (item: MeteringRecord) => <NavLink to={`/metering-records/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Meter'),
        width: '1fr',
        render: (item: MeteringRecord) => <NavLink to={`/meters/${item.meterId}`}>{item.meterId}</NavLink>,
    },
    {
        title: I18n.get('Meter Value'),
        width: '1fr',
        render: (item: MeteringRecord) => item.meterValue,
    },
    {
        title: I18n.get('Timestamp'),
        width: '1fr',
        render: (item: MeteringRecord) => formatTimestamp(item.timestamp as string),
    },
    {
        title: I18n.get('Dimensions'),
        width: '1fr',
        render: (item: MeteringRecord) => item.dimensions ? item.dimensions.length : '-',
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: MeteringRecord) => formatTimestamp(item.created as string),
    },
];
