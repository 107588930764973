import platform from "./platform";
import {stage} from './stage';
import kinesis from './kinesis.js';
import permissions from "./permissions.ts";


const config = {
    platform,
    stage,
    kinesis,
    appTitle: "Flexi Billing Console",
    appSubTitle: "Flexi Billing Console",
    appLogoUrl: "",
    permissions
};

export default config;
