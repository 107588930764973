import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/PricesService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Price} from '../types';

const entityKey = 'prices';

interface PricesState {
    byId: { [key: string]: Price },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as PricesState;


const normalizeRecord = (record: Price) => {
    // return Object.assign({}, record, {'unit_amount': (parseInt(record.unit_amount) / 100)});
    return Object.assign({}, record,);
};

// generates pending, fulfilled and rejected
export const fetchPrices = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: Price) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchPrice = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const savePrice = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Price) => {

        // const modified = Object.assign({}, payload, {unit_amount: parseInt(payload.unit_amount) * 100})
        const modified = Object.assign({}, payload,)

        return add(modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updatePrice = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Price }) => {

        // const modified = Object.assign({}, record, {unit_amount: parseInt(record.unit_amount) * 100})
        const modified = Object.assign({}, record,)

        return update(id, modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deletePrice = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storePrice = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Price) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchPrices, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchPrice);

                                            // SAVE ONE
                                            saveOneCase(builder, savePrice);

                                            // UPDATE ONE
                                            updateOneCase(builder, updatePrice);

                                            updateOneCase(builder, storePrice);

                                            // DELETE ONE
                                            deleteOneCase(builder, deletePrice);
                                        },
                                    });

export default componentsSlice.reducer;
