export const listRatePlans = `
   query listRatePlans {
       listRatePlans {
           items {
              id
              name
              chargeTiers {
                lowerBand
                upperBand
                rate
              }
              createdByUsername
              managedByUsername
              created
              updated
           }
       }
   }
`;

export const getRatePlan = `
   query getRatePlan($id:ID!) {
       getRatePlan(id:$id) {
         id
         name
         chargeTiers {
           lowerBand
           upperBand
           rate
         } 
         created
         updated
       } 
}`;
