import {UsageRecord} from "../types.ts";
import {v4 as uuid4} from "uuid";

function pickRandom(list: string[]) {
    return list[Math.floor(Math.random() * list.length)];
}

const generateRandomUsageRecord = () => {

    const action = pickRandom(['added', 'removed']);
    const recordId = pickRandom([uuid4(), uuid4(), uuid4()]);
    const usageType = pickRandom(['ACCOUNT', 'ORGANIZATION', 'REGISTRAR', 'DOMAIN_NAME', 'ALIAS', 'MESSAGE']);
    const username = pickRandom(['openidconnectprovider_9861a3e0-90d1-70f4-5dd9-1fedc8aabe7b', 'google_101785956163867340574', '895a6ac3-4402-4151-a035-6b15e8eb3eca']);

    const values = {
        action: action,
        created: new Date().toISOString(),
        recordId: recordId,
        usageType: usageType,
        username: username,
        value: 1,
    } as UsageRecord;


    return values;
};

export const useRandomUsageRecordGenerator = () => {
    return {
        generateRandomUsageRecord,
    };
};

