import {FlexiAddButton,} from '@flexinet/ui-components';

import {Title,} from '@mantine/core';

import {useParams} from 'react-router-dom';
import {useInvoiceItem} from "../hooks/useInvoiceItem.ts";


import {openModal,} from '@mantine/modals';


import {RecordForm} from "../forms/RecordForm.tsx";


export const AddRecordButton = () => {


    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter');
    }

    const {createRecord} = useInvoiceItem(invoiceId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Invoice Item</Title>,
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           invoice: invoiceId,
                                                       }}
                                                   />
                                               ),
                                           });


    return (
        <FlexiAddButton onClick={onClickHandler}>Add Invoice Item</FlexiAddButton>
    );
};


