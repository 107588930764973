export const listProducts = `
   query listProducts {
       listProducts {
           items {
              id
              name
              description
              billingProviderId
              billingProviderRecordId
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getProduct = `
   query getProduct($id:ID!) {
       getProduct(id:$id) {
        id
        name
        description
        billingProviderId
        billingProviderRecordId
        created
        updated
       } 
}`;
