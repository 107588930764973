export const listPrices = `
   query listPrices {
       listPrices {
           items {
              id
              productId
              pricingModel
              ratePlanId
           }
       }
   }
`;

export const getPrice = `
   query getPrice($id:ID!) {
       getPrice(id:$id) {
         id
         productId
         pricingModel
         ratePlanId
       } 
}`;
