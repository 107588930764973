import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {BillingProvider} from '../types';
import {Badge} from "@mantine/core";

export const columns = [
    {
        title: I18n.get('Billing Provider Name'),
        width: '1fr',
        render: (item: BillingProvider) => <NavLink to={`/billing-providers/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: BillingProvider) => item.status && item.status === 'Enabled' ?
            <Badge color={'green'}>Enabled</Badge> : <Badge color={'red'}>Disabled</Badge>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: BillingProvider) => formatTimestamp(item.created as string),
    },
];
