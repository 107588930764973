import {
    deleteBillingProvider,
    fetchBillingProvider,
    saveBillingProvider,
    updateBillingProvider,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {BillingProvider} from '../types';

export const useBillingProvider = (customerId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, customerId ? customerId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }
        dispatch(fetchBillingProvider(customerId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: BillingProvider) => {
        dispatch(saveBillingProvider(values));
    };

    const updateRecord = (values: BillingProvider) => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }

        dispatch(updateBillingProvider({
                                           id: customerId,
                                           record: values,
                                       }));
    };

    const deleteRecord = () => {
        if (typeof customerId === 'undefined') {
            throw new Error('customerId is required parameter');
        }
        dispatch(deleteBillingProvider(customerId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
