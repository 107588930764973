import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {ServicePlan} from '../types';
import {StatusBadge} from "../profile/StatusBadge.tsx";

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: ServicePlan) => <NavLink to={`/service-plans/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Free Trial'),
        width: '1fr',
        render: (item: ServicePlan) => item.freeTrial ? 'yes' : 'no',
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: ServicePlan) => <StatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: ServicePlan) => formatTimestamp(item.created as string),
    },
];
