import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/BillingAccountsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {BillingAccount} from '../types';

const entityKey = 'billingAccounts';

interface BillingAccountsState {
    byId: { [key: string]: BillingAccount },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as BillingAccountsState;


const normalizeRecord = (record: BillingAccount) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchBillingAccounts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: BillingAccount) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchBillingAccount = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveBillingAccount = createAsyncThunk(
    `${entityKey}/add`,
    (payload: BillingAccount) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateBillingAccount = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: BillingAccount }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteBillingAccount = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeBillingAccount = createAsyncThunk(
    `${entityKey}/store`,
    (payload: BillingAccount) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchBillingAccounts, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchBillingAccount);

                                            // SAVE ONE
                                            saveOneCase(builder, saveBillingAccount);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateBillingAccount);

                                            updateOneCase(builder, storeBillingAccount);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteBillingAccount);
                                        },
                                    });

export default componentsSlice.reducer;
