import {BillingAccount,} from '../types';
import {
    DashboardWidget,
    WidgetBody,
    WidgetDataPoint,
    WidgetFooter,
    WidgetHeader,
    WidgetType
} from "@flexinet/ui-components";
import {NavLink} from "react-router-dom";


export const CurrentInvoiceWidget = ({record}: { record: BillingAccount }) => {

    const currentInvoiceAmountWidget: WidgetType = {
        header: {
            title: `Current Invoice`,
        },
        body: {
            dataPoints: [
                {
                    value: 1250,
                    label: `Period End: ${record.name}`,
                    color: 'blue',
                },
            ] as WidgetDataPoint[],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View Details',
                    component: {NavLink},
                    to: '/invoices',
                    visible: true,
                },
            ],
        }
    };

    return (
        <DashboardWidget
            header={<WidgetHeader  {...currentInvoiceAmountWidget.header}/>}
            body={<WidgetBody {...currentInvoiceAmountWidget.body}/>}
            footer={<WidgetFooter {...currentInvoiceAmountWidget.footer}/>}
        />
    );


};
