import {I18n} from '@aws-amplify/core';

import {FlexiAddButton,} from '@flexinet/ui-components';

import {useParams} from 'react-router-dom';
import {openModal} from "@mantine/modals";
import {Title} from "@mantine/core";
import {RecordForm} from "../forms/RecordForm.tsx";
import {usePaymentProfile} from '../hooks/usePaymentProfile.ts';
import {PaymentProfileStatus} from "../types.ts";

export const AddRecordButton = (
    {
        buttonText = 'Add Payment Profile',
        size = 'sm',
    }: {
        buttonText?: string,
        size?: string,
    }) => {

    const {id: billingAccountId} = useParams()

    if (typeof billingAccountId === 'undefined') {
        throw new Error('billingAccountId is required parameter')
    }

    // const navigate = useNavigate();
    //
    // if (typeof onClick === 'undefined') {
    //     onClick = () => {
    //         navigate(`/billing-accounts/${billingAccountId}/payment-profiles/add`);
    //     };
    // }

    const {createRecord} = usePaymentProfile(billingAccountId);


    const onClickHandler = () => openModal({
                                               title: <Title order={3}>Add Payment Profiles</Title>,
                                               size: 'xl',
                                               children: (
                                                   <RecordForm
                                                       handleSubmit={(values) => {
                                                           createRecord(values);
                                                           //closeAllModals();
                                                       }}
                                                       record={{
                                                           name: '',
                                                           status: PaymentProfileStatus.Active,
                                                           billingAccountId: billingAccountId,
                                                           created: new Date().toISOString(),
                                                       }}
                                                   />
                                               ),
                                           });

    return (
        <FlexiAddButton
            onClick={onClickHandler}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};
