export const listMeters = `
   query listMeters {
       listMeters {
           items {
              id
              name
              apiKey
              type
              status
              dimensions {
                 name
                 apiKey
              }
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getMeter = `
   query getMeter($id:ID!) {
       getMeter(id:$id) {
         id
         name
         apiKey
         type
         status
         dimensions {
           name
           apiKey
         }
         created
         updated
       } 
}`;
