import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useParams} from 'react-router-dom';

import {useInvoiceItems} from '../hooks/useInvoiceItems.ts';


export const ReloadRecordsButton = () => {

    const {id: invoiceId} = useParams();

    if (typeof invoiceId === 'undefined') {
        throw new Error('invoiceId is required parameter')
    }

    const {fetchRecords} = useInvoiceItems(invoiceId);
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
