import {z} from "zod";
import {BasePlatformObject} from "../../../schemas";

export const UsageRecordSchema = BasePlatformObject.extend(
    {
        action: z.string()
                 .min(1, 'action is required'),
        created: z.string()
                  .datetime()
                  .min(1, 'created is required'),
        id: z.string()
             .min(1, 'id is required'),
        recordId: z.string()
                   .min(1, 'recordId is required'),
        usageType: z.string()
                    .min(1, 'usageType is required'),
        username: z.string()
                   .min(1, 'username is required'),
        value: z.number()
                .min(1, 'value is required'),
    })

export const GetUsageRecordParser = z.object(
    {
        data: z.object({getUsageRecord: UsageRecordSchema})
    })