import {useEffect} from 'react';

import {MultiSelect} from '@mantine/core';

import {allComponentsSelector} from '../selectors';

import {usePrices} from '../hooks/usePrices.ts';
import {FieldProps} from 'formik';
import {Price} from '../types';
import {useAppSelector} from "../../../hooks/useTypedReactRedux.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

export const PricesMultiSelect = ({
                                      field: {
                                          name,
                                          value,
                                      },
                                      form: {setFieldValue},
                                      ...rest
                                  }: FieldProps) => {


    const {
        user
    } = useAuth();

    if (typeof user === 'undefined' || !user) {
        throw new Error('user is required parameter')
    }

    const {fetchRecords} = usePrices();

    const component = useAppSelector((state) => allComponentsSelector(state));

    useEffect(() => {
        fetchRecords();
    }, []);

    const data = component.map((component: Price) => {
        return {
            value: component.id,
            label: component.id,
        };
    });

    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
