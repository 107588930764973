import {ServicePlan,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";


export const MetadataPropertyCard = ({record}: { record: ServicePlan }) => {

    const properties = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : null,
            rbac: 'UpdateServicePlan',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 6,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 6,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};
