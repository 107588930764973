import {
    Field,
    FormikProps
} from 'formik';

import {
    FormikRadioCardGroup,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {
    Stack,
    Switch
} from "@mantine/core";
import {BillingAccountsAutocomplete} from "../../billing-accounts/inputs/BillingAccountsAutocomplete.tsx";
import {
    PaymentProfile,
    PaymentProfileStatus
} from "../types.ts";
import {useState} from "react";


export const FormComponent = (props: FormikProps<PaymentProfile>) => {

    const initialOption = props.values.description ? true : false
    const [showDescription, setShowDescription] = useState(initialOption)

    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Payment Profile'}>

                <Field
                    name='name'
                    label='Name'
                    description='Please enter the paymentProfile name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='status'
                    label='Status'
                    description='Please enter payment profile status'
                    component={FormikRadioCardGroup}
                    data={[{
                        label: PaymentProfileStatus.Active,
                        value: PaymentProfileStatus.Active
                    }, {
                        label: PaymentProfileStatus.Inactive,
                        value: PaymentProfileStatus.Inactive
                    }]}
                    withAsterisk
                    my={'xl'}
                />

                <Switch
                    checked={showDescription}
                    label={'Add Profile Description'}
                    onChange={(event) => setShowDescription(event.currentTarget.checked)}
                    my={'xl'}
                />

                {showDescription ?
                    <Field
                        name='description'
                        label='Description'
                        description='Please enter paymentProfile description'
                        component={FormikTextarea}
                        my={'xl'}
                    /> : null}

            </PlatformFieldset>

            <PlatformFieldset legend={'Contact Info'}>

                <Field
                    name={'contactInfo.email'}
                    label={'Email'}
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='contactInfo.phone'
                    label='Phone'
                    description='Please enter phone'
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='contactInfo.website'
                    label='Website'
                    description='Please enter website'
                    component={FormikTextInput}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Payment Info'}>

                <Field
                    name={'paymentInfo.email'}
                    label={'Payment Method'}
                    component={FormikRadioCardGroup}
                    data={[{
                        label: 'Credit Card',
                        value: 'cc'
                    }, {
                        label: 'Bank Transfer',
                        value: 'bank'
                    }]}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Metadata'}>
                <Field
                    name='billingAccountId'
                    label='Billing Account'
                    description='Please enter the billing account'
                    component={BillingAccountsAutocomplete}
                    withAsterisk
                    my={'xl'}
                />
            </PlatformFieldset>


        </Stack>
    );
};
