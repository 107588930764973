import {
    BillingAccount,
    IBillingAccountTableColumn
} from "../types.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {RowSelectionDataCell} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {useBillingAccountsSelection} from "./useBillingAccountsSelection.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {BillingAccountStatusBadge} from "../components/BillingAccountStatusBadge.tsx";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";

export const useRecordsTableColumns = () => {
    let columns: IBillingAccountTableColumn[] = [];

    const {allowedActions} = useVerifiedPermissions()

    const {
        handleRowSelection,
    } = useBillingAccountsSelection();

    if (allowedActions.includes(rbacActions.DeleteBillingAccount)) {

        columns.push({
                         id: 'select',
                         title: '',
                         render: item => <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
                     },
        );
    }

    const additional = [
        {
            title: I18n.get('Billing Account Name'),
            width: '1fr',
            render: (item: BillingAccount) => <NavLink to={`/billing-accounts/${item.id}`}>{item.name}</NavLink>,
        },
        {
            title: I18n.get('Status'),
            width: '1fr',
            render: (item: BillingAccount) => <BillingAccountStatusBadge status={item.status}/>,
        },
        {
            title: I18n.get('Created'),
            width: '1fr',
            render: (item: BillingAccount) => formatTimestamp(item.created as string),
        },
    ];

    columns = columns.concat(additional);


    return {
        columns,
    }
}