import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {theme} from "./theme.ts";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import "@flexinet/ui-components/style.css"


import {AuthProvider} from "./context/AuthContext.tsx";
import {VerifiedPermissionsProvider} from './context/VerifiedPermissionsProvider.tsx';
import {actions} from './config/rbac/actions.ts';
import {routesConfig} from "./config/routes.tsx";


const router = createBrowserRouter(routesConfig)

export const App = () => {

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <VerifiedPermissionsProvider actions={actions}>
                    <UserAttributesContextProvider>
                        <ModalsProvider>
                            <Notifications position={'bottom-left'}/>
                            <RouterProvider router={router}/>
                        </ModalsProvider>
                    </UserAttributesContextProvider>
                </VerifiedPermissionsProvider>
            </AuthProvider>
        </MantineProvider>
    );
}

