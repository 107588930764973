import {I18n} from '@aws-amplify/core';

import {FlexiAddButton,} from '@flexinet/ui-components';
import {useUsageRecord} from "../hooks/useUsageRecord.ts";
import {UsageRecord} from "../types.ts";
import {useRandomUsageRecordGenerator} from "../hooks/useRandomUsageRecordGenerator.ts";


export const AddTestRecordButton = ({
                                        buttonText = 'Add Test Record',
                                        size = 'sm',
                                        onClick,
                                    }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {

    const {generateRandomUsageRecord} = useRandomUsageRecordGenerator()
    const {createRecord} = useUsageRecord();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            const values = generateRandomUsageRecord() as UsageRecord;
            createRecord(values);
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};
