export enum rbacActions {
    ViewDashboard = 'ViewDashboard',
    ViewProfile = 'ViewProfile',


    ListBillingProviders = 'ListBillingProviders',
    GetBillingProvider = 'GetBillingProvider',
    CreateBillingProvider = 'CreateBillingProvider',
    UpdateBillingProvider = 'UpdateBillingProvider',
    DeleteBillingProvider = 'DeleteBillingProvider',

    ListCustomers = 'ListCustomers',
    GetCustomer = 'GetCustomer',
    CreateCustomer = 'CreateCustomer',
    UpdateCustomer = 'UpdateCustomer',
    DeleteCustomer = 'DeleteCustomer',

    ListMeteringRecords = 'ListMeteringRecords',
    GetMeteringRecord = 'GetMeteringRecord',
    CreateMeteringRecord = 'CreateMeteringRecord',
    UpdateMeteringRecord = 'UpdateMeteringRecord',
    DeleteMeteringRecord = 'DeleteMeteringRecord',

    ListUsageRecords = 'ListUsageRecords',
    GetUsageRecord = 'GetUsageRecord',
    CreateUsageRecord = 'CreateUsageRecord',
    UpdateUsageRecord = 'UpdateUsageRecord',
    DeleteUsageRecord = 'DeleteUsageRecord',

    ListMeters = 'ListMeters',
    GetMeter = 'GetMeter',
    CreateMeter = 'CreateMeter',
    UpdateMeter = 'UpdateMeter',
    DeleteMeter = 'DeleteMeter',

    ListOrders = 'ListOrders',
    GetOrder = 'GetOrder',
    CreateOrder = 'CreateOrder',
    UpdateOrder = 'UpdateOrder',
    DeleteOrder = 'DeleteOrder',

    ListProducts = 'ListProducts',
    GetProduct = 'GetProduct',
    CreateProduct = 'CreateProduct',
    UpdateProduct = 'UpdateProduct',
    DeleteProduct = 'DeleteProduct',

    ListRatePlans = 'ListRatePlans',
    GetRatePlan = 'GetRatePlan',
    CreateRatePlan = 'CreateRatePlan',
    UpdateRatePlan = 'UpdateRatePlan',
    DeleteRatePlan = 'DeleteRatePlan',

    ListSubscriptions = 'ListSubscriptions',
    GetSubscription = 'GetSubscription',
    CreateSubscription = 'CreateSubscription',
    UpdateSubscription = 'UpdateSubscription',
    DeleteSubscription = 'DeleteSubscription',

    ListServicePlans = 'ListServicePlans',
    GetServicePlan = 'GetServicePlan',
    CreateServicePlan = 'CreateServicePlan',
    UpdateServicePlan = 'UpdateServicePlan',
    DeleteServicePlan = 'DeleteServicePlan',

    ListInvoices = 'ListInvoices',
    GetInvoice = 'GetInvoice',
    CreateInvoice = 'CreateInvoice',
    UpdateInvoice = 'UpdateInvoice',
    DeleteInvoice = 'DeleteInvoice',

    ListInvoiceItems = 'ListInvoiceItems',
    GetInvoiceItem = 'GetInvoiceItem',
    CreateInvoiceItem = 'CreateInvoiceItem',
    UpdateInvoiceItem = 'UpdateInvoiceItem',
    DeleteInvoiceItem = 'DeleteInvoiceItem',

    ListPrices = 'ListPrices',
    GetPrice = 'GetPrice',
    CreatePrice = 'CreatePrice',
    UpdatePrice = 'UpdatePrice',
    DeletePrice = 'DeletePrice',

    ListBillingAccounts = 'ListBillingAccounts',
    GetBillingAccount = 'GetBillingAccount',
    CreateBillingAccount = 'CreateBillingAccount',
    UpdateBillingAccount = 'UpdateBillingAccount',
    DeleteBillingAccount = 'DeleteBillingAccount',

    ListPaymentProfiles = 'ListPaymentProfiles',
    GetPaymentProfile = 'GetPaymentProfile',
    CreatePaymentProfile = 'CreatePaymentProfile',
    UpdatePaymentProfile = 'UpdatePaymentProfile',
    DeletePaymentProfile = 'DeletePaymentProfile',

}

export const actions = [
    rbacActions.ViewDashboard,
    rbacActions.ViewProfile,

    rbacActions.ListBillingProviders,
    rbacActions.GetBillingProvider,
    rbacActions.CreateBillingProvider,
    rbacActions.UpdateBillingProvider,
    rbacActions.DeleteBillingProvider,

    rbacActions.ListCustomers,
    rbacActions.GetCustomer,
    rbacActions.CreateCustomer,
    rbacActions.UpdateCustomer,
    rbacActions.DeleteCustomer,

    rbacActions.ListMeteringRecords,
    rbacActions.GetMeteringRecord,
    rbacActions.CreateMeteringRecord,
    rbacActions.UpdateMeteringRecord,
    rbacActions.DeleteMeteringRecord,

    rbacActions.ListUsageRecords,
    rbacActions.GetUsageRecord,
    rbacActions.CreateUsageRecord,
    rbacActions.UpdateUsageRecord,
    rbacActions.DeleteUsageRecord,

    rbacActions.ListMeters,
    rbacActions.GetMeter,
    rbacActions.CreateMeter,
    rbacActions.UpdateMeter,
    rbacActions.DeleteMeter,

    rbacActions.ListOrders,
    rbacActions.GetOrder,
    rbacActions.CreateOrder,
    rbacActions.UpdateOrder,
    rbacActions.DeleteOrder,

    rbacActions.ListProducts,
    rbacActions.GetProduct,
    rbacActions.CreateProduct,
    rbacActions.UpdateProduct,
    rbacActions.DeleteProduct,

    rbacActions.ListRatePlans,
    rbacActions.GetRatePlan,
    rbacActions.CreateRatePlan,
    rbacActions.UpdateRatePlan,
    rbacActions.DeleteRatePlan,

    rbacActions.ListSubscriptions,
    rbacActions.GetSubscription,
    rbacActions.CreateSubscription,
    rbacActions.UpdateSubscription,
    rbacActions.DeleteSubscription,

    rbacActions.ListServicePlans,
    rbacActions.GetServicePlan,
    rbacActions.CreateServicePlan,
    rbacActions.UpdateServicePlan,
    rbacActions.DeleteServicePlan,

    rbacActions.ListInvoices,
    rbacActions.GetInvoice,
    rbacActions.CreateInvoice,
    rbacActions.UpdateInvoice,
    rbacActions.DeleteInvoice,

    rbacActions.ListInvoiceItems,
    rbacActions.GetInvoiceItem,
    rbacActions.CreateInvoiceItem,
    rbacActions.UpdateInvoiceItem,
    rbacActions.DeleteInvoiceItem,

    rbacActions.ListPrices,
    rbacActions.GetPrice,
    rbacActions.CreatePrice,
    rbacActions.UpdatePrice,
    rbacActions.DeletePrice,

    rbacActions.ListBillingAccounts,
    rbacActions.GetBillingAccount,
    rbacActions.CreateBillingAccount,
    rbacActions.UpdateBillingAccount,
    rbacActions.DeleteBillingAccount,

    rbacActions.ListPaymentProfiles,
    rbacActions.GetPaymentProfile,
    rbacActions.CreatePaymentProfile,
    rbacActions.UpdatePaymentProfile,
    rbacActions.DeletePaymentProfile,


];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);