import {stage} from './stage';

const platform = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        billing: 'https://billing.flexi.network',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        billing: 'https://billing.test.flexi.network',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        billing: 'https://billing.dev.flexi.network',
    },
    ide: {
        portal: 'https://localhost:8011',
        console: 'https://localhost:8012',
        billing: 'https://localhost:8015',
    },
};

export default platform[stage];
