import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";


export const notifySuccess = (message = 'Record saved successfully') => {
    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'teal',
                     })
}

export const notifyError = (e: any | unknown | undefined = 'Error performing the operation') => {
    if (typeof e === 'string') {
        e = {message: e}
    }

    if (typeof e.errors !== 'undefined' && e.errors.length > 0) {
        e.errors.forEach((error: { message: string; }) => {

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: error.message,
                                 color: 'red',
                             })


        })
    } else {

        showNotification({
                             title: 'Error',
                             icon: <AiOutlineExclamation/>,
                             message: e.message,
                             color: 'red',
                         })

    }

}

