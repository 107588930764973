import {
    getPaymentProfile,
    listPaymentProfiles
} from './queries';
import {
    createPaymentProfile,
    deletePaymentProfile,
    updatePaymentProfile,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {PaymentProfile} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";


export type ListPaymentProfiles = {
    listPaymentProfiles: { items: PaymentProfile[] }
}

export type GetPaymentProfile = {
    getPaymentProfile: PaymentProfile
}

export type CreatePaymentProfile = {
    createPaymentProfile: PaymentProfile
}

export type UpdatePaymentProfile = {
    updatePaymentProfile: PaymentProfile
}

export type DeletePaymentProfile = {
    deletePaymentProfile: string
}


const client = generateClient();


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListPaymentProfiles>>({
                                                                                     query: listPaymentProfiles,
                                                                                     variables: {filter: filter},
                                                                                 });
        return response.data?.listPaymentProfiles.items;
    } catch (e: any) {
        console.trace(e);
        if (typeof e !== 'undefined' && typeof e.errors !== 'undefined') {
            const unauthorizedExceptionFound = e.errors.find((item: {
                errorType: string,
                message: string
            }) => item.errorType === 'UnauthorizedException')
            if (unauthorizedExceptionFound) {
                window.location.href = '/'
            }
        }
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPaymentProfile>>({
                                                                                   query: getPaymentProfile,
                                                                                   variables: {id: id},
                                                                               });
        return response.data?.getPaymentProfile;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreatePaymentProfile>>({
                                                                                      query: createPaymentProfile,
                                                                                      variables: {input: payload},
                                                                                  });
        console.debug('in the service response', response);
        notifySuccess('PaymentProfile created')
        return response.data?.createPaymentProfile;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: PaymentProfile) => {

    console.debug('IN UPDATE', id, record);

    const {
        createdByUsername,
        created,
        updated,
        ...rest
    } = record

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePaymentProfile>>({
                                                                                      query: (updatePaymentProfile),
                                                                                      variables: {input: rest},
                                                                                  });
        notifySuccess('PaymentProfile updated')
        return response.data?.updatePaymentProfile;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePaymentProfile>>({
                                                                                      query: (deletePaymentProfile),
                                                                                      variables: {input: {id: id}},

                                                                                  });
        notifySuccess('PaymentProfile deleted')
        return response.data?.deletePaymentProfile;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
