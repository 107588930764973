import {
    Grid,
    Paper,
    Stack,
    Table,
    Text,
    Title,
} from '@mantine/core';
import {RatePlan,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty,
    SimplePageContentWrapper,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const Profile = ({record}: { record: RatePlan }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Charge Tiers',
            value: record.chargeTiers ? record.chargeTiers.length : 'n/a',
            span: 12,
        }] as PlatformPropertyCardProperty[];

    const properties2 = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateRatePlan',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateRatePlan',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];
    const allowedProperties2 = filterAllowed(properties2) as PlatformPropertyCardProperty[];

    console.debug('allowedProperties', record)

    let chargeTiersData = [] as { id: string, lower: string, upper: string, rate: string }[];
    if (record.chargeTiers) {
        // create table data items with id and name
        chargeTiersData = record.chargeTiers.map((chargeTier, index) => ({
            id: index.toString(),
            lower: chargeTier.lowerBand,
            upper: chargeTier.upperBand,
            rate: chargeTier.rate,
        }));
        console.debug('chargeTiersData', chargeTiersData)
    }

    return (
        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 4
            }}>
                <Stack gap={'xl'}>
                    <PlatformPropertyCard properties={allowedProperties}/>
                    <PlatformPropertyCard properties={allowedProperties2}/>
                </Stack>

            </Grid.Col>

            <Grid.Col span={{
                base: 12,
                lg: 8
            }}>
                <SimplePageContentWrapper>
                    <Title order={2}>Charge Tiers</Title>
                    <Paper p={'lg'}>
                        <Table verticalSpacing='sm' striped highlightOnHover>
                            <TableHeaderRow
                                columns={[{
                                    id: 'lower',
                                    title: 'Lower Band'
                                }, {
                                    id: 'upper',
                                    title: 'Upper Band'
                                }, {
                                    id: 'rate',
                                    title: 'Rate'
                                }] as TableHeaderColumn[]}
                                handleSelectAllRows={() => {
                                }}
                            />
                            <TableBody
                                items={chargeTiersData}
                                loading={false}
                                selection={{}}
                                columns={[{
                                    id: 'lower',
                                    title: 'Lower Band',
                                    render: (item) => <Text>{item.lower}</Text>
                                }, {
                                    id: 'upper',
                                    title: 'Upper Band',

                                    render: (item) => <Text>{item.upper}</Text>
                                }, {
                                    id: 'rate',
                                    title: 'Rate',
                                    render: (item) => <Text>{item.rate}</Text>
                                }] as TableBodyColumn[]}
                            />
                        </Table>
                    </Paper>
                </SimplePageContentWrapper>

            </Grid.Col>
        </Grid>
    );


};