import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        enabled: boolean;
        policyStoreId: string;
        region: string;
    };
};

const config: ConfigShape = {
    prod: {
        enabled: true,
        policyStoreId: '2xuwcSULXq2oy46ibPR5Vg',
        region: 'us-west-2',
    },
    test: {
        enabled: true,
        policyStoreId: '59i51kMo7dg5KhVCj1iba7',
        region: 'us-west-2',
    },
    dev: {
        enabled: true,
        policyStoreId: 'DxhedJVmvrcpq4fMtqoD27',
        region: 'us-west-2',
    },
    ide: {
        enabled: true,
        policyStoreId: 'DxhedJVmvrcpq4fMtqoD27',
        region: 'us-west-2',
    },
};

export default config[stage];
