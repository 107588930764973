export const listMeteringRecords = `
   query listMeteringRecords($filter: MeteringRecordsFilterInput) {
       listMeteringRecords(filter: $filter) {
           items {
                id
                customerId
                meterId
                meterValue
                timestamp
                dimensions {
                    apiKey
                    value
                }
                created
                updated
           }
       }
   }
`;

export const getMeteringRecord = `
   query getMeteringRecord($id:ID!) {
       getMeteringRecord(id:$id) {
                id
                customerId
                meterId
                meterValue
                timestamp
                dimensions {
                    apiKey
                    value
                }
                created
                updated
       } 
}`;
