import {Field} from 'formik';

import {
    FormikRadioCardGroup,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {SimpleGrid} from "@mantine/core";
import {BillingProvidersDropdown} from "../../billing-providers/inputs/BillingProvidersDropdown.tsx";


export const FormComponent = () => {

    return (
        <SimpleGrid cols={2}>
            <PlatformFieldset legend={'Customer'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the customer name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='status'
                    label='Status'
                    description='Please enter customer status'
                    component={FormikRadioCardGroup}
                    data={[{
                        label: 'Active',
                        value: 'Active'
                    }, {
                        label: 'Inactive',
                        value: 'Inactive'
                    }]}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Description'
                    description='Please enter customer description'
                    component={FormikTextarea}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Billing Info'}>


                <Field
                    name={'billingInfo.billingProviderId'}
                    label={'Billing Provider ID'}
                    component={BillingProvidersDropdown}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='billingInfo.billingProviderRecordId'
                    label='Billing Provider Record ID'
                    description='Please enter record ID'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='billingInfo.billingZip'
                    label='Billing ZIP'
                    description='Please enter billing ZIP'
                    component={FormikTextInput}
                    my={'xl'}
                />


            </PlatformFieldset>

            <PlatformFieldset legend={'Contact Info'}>

                <Field
                    name={'contactInfo.email'}
                    label={'Email'}
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='contactInfo.phone'
                    label='Phone'
                    description='Please enter phone'
                    component={FormikTextInput}
                    my={'xl'}
                />

                <Field
                    name='contactInfo.website'
                    label='Website'
                    description='Please enter website'
                    component={FormikTextInput}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Payment Info'}>

                <Field
                    name={'paymentInfo.email'}
                    label={'Payment Method'}
                    component={FormikRadioCardGroup}
                    data={[{
                        label: 'Credit Card',
                        value: 'cc'
                    }, {
                        label: 'Bank Transfer',
                        value: 'bank'
                    }]}
                    my={'xl'}
                />

            </PlatformFieldset>
        </SimpleGrid>
    );
};
