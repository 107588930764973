import {Meter,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {StatusBadge} from "../components/StatusBadge.tsx";


export const InformationPropertyCard = ({record}: { record: Meter }) => {

    const properties = [
        {
            label: 'Display Name',
            value: record.name as string,
            span: 12,
        },
        {
            label: 'Status',
            value: <StatusBadge status={record.status}/>,
            span: 12,
        }, {
            label: 'ID',
            value: record.id as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'API Key',
            value: record.apiKey as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        },
        {
            label: 'Type',
            value: record.type as string,
            span: 12,
        },
    ] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};
