export const listBillingProviders = `
   query listBillingProviders {
       listBillingProviders {
           items {
              id
              key
              name
              description
              status
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getBillingProvider = `
   query getBillingProvider($id:ID!) {
       getBillingProvider(id:$id) {
         id
         key
         name
         description
         status    
         created
         updated
       } 
}`;
