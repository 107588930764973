import {
    createContext,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import {
    fetchUserAttributes,
    signOut
} from 'aws-amplify/auth';
import {useAuth} from "../hooks/useAuth.ts";

type UserAttributesContextType = {
    givenName: string,
    familyName: string,
    name: string
    email: string,
    companyName: string,
    tier: string
}

export const UserAttributesContext = createContext<UserAttributesContextType>({
                                                                                  givenName: '',
                                                                                  familyName: '',
                                                                                  name: '',
                                                                                  email: '',
                                                                                  companyName: '',
                                                                                  tier: '',
                                                                              });


type UserAttributesContextProviderProps = {
    children?: ReactNode;
}

export const UserAttributesContextProvider = ({
                                                  children,
                                              }: UserAttributesContextProviderProps) => {


    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [tier, setTier] = useState('')

    const {
        user
    } = useAuth()


    useEffect(() => {
        const fetchAttributes = async () => {
            if (!user) {
                return
            }

            let attributes = {} as { [key: string]: any }
            try {
                attributes = await fetchUserAttributes();
            } catch (e: unknown) {
                if (e instanceof Error) {
                    switch (e.name) {
                        case 'NotAuthorizedException':
                            console.debug('Message', e.message)
                            if (e.message.includes('Token is inactive')) {
                                await signOut()
                                window.location.href = '/';
                            }
                            break
                    }
                }
            }


            if (Array.isArray(Object.keys(attributes)) && Object.keys(attributes).length > 0) {

                Object.keys(attributes)
                      .forEach((key: string) => {

                          switch (key) {
                              case 'given_name':
                                  setGivenName(attributes[key])
                                  break
                              case 'family_name':
                                  setFamilyName(attributes[key])
                                  break
                              case 'name':
                                  setName(attributes[key])
                                  break
                              case 'email':
                                  setEmail(attributes[key])
                                  break
                              case 'custom:company_name':
                                  setCompanyName(attributes[key])
                                  break
                              case 'custom:tier':
                                  setTier(attributes[key])
                                  break
                          }
                      })

            }

        }

        fetchAttributes()

    }, [user])


    if (!user) {
        return children
    }

    return (
        <UserAttributesContext.Provider
            value={{
                givenName,
                familyName,
                name,
                email,
                companyName,
                tier
            }}
        >
            {children}
        </UserAttributesContext.Provider>
    );
};

