export const createBillingAccount = `
    mutation createBillingAccount($input: CreateBillingAccountInput!) {
        createBillingAccount(input:$input) {
            id
            name
            description      
            status 
            billingProviderId
            billingProviderRecordId
            created
            updated  
    }
}`;

export const updateBillingAccount = `
    mutation updateBillingAccount($input: UpdateBillingAccountInput!) {
        updateBillingAccount(input:$input){
            id
            name
            description
            status  
            billingProviderId
            billingProviderRecordId
            created
            updated       
        }
}`;

export const deleteBillingAccount = `
    mutation deleteBillingAccount($input: DeleteBillingAccountInput!) {
        deleteBillingAccount(input:$input) 
}`;
