import {FormikSelect} from '@flexinet/ui-components';
import {useBillingProviders} from '../hooks/useBillingProviders.ts';
import {BillingProvider} from '../types';
import {FieldProps} from 'formik';
import {SelectProps} from '@mantine/core';
import {useEffect} from "react";


export const BillingProvidersDropdown = (props: FieldProps & SelectProps) => {

    const {
        items,
        fetchRecords
    } = useBillingProviders();

    useEffect(() => {
        fetchRecords();
    }, []);

    const selectOptions = items.map((app: BillingProvider) => {
        return {
            value: app.id,
            label: app.name,
        };
    });

    return <FormikSelect {...props} data={selectOptions}/>;
};