import {
    deletePaymentProfile,
    fetchPaymentProfile,
    savePaymentProfile,
    updatePaymentProfile,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {PaymentProfile} from '../types';

export const usePaymentProfile = (paymentProfileId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, paymentProfileId ? paymentProfileId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof paymentProfileId === 'undefined') {
            throw new Error('paymentProfileId is required parameter');
        }
        dispatch(fetchPaymentProfile(paymentProfileId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: PaymentProfile) => {
        dispatch(savePaymentProfile(values));
    };

    const updateRecord = (values: PaymentProfile) => {
        if (typeof paymentProfileId === 'undefined') {
            throw new Error('paymentProfileId is required parameter');
        }

        dispatch(updatePaymentProfile({
                                          id: paymentProfileId,
                                          record: values,
                                      }));
    };

    const deleteRecord = () => {
        if (typeof paymentProfileId === 'undefined') {
            throw new Error('paymentProfileId is required parameter');
        }
        dispatch(deletePaymentProfile(paymentProfileId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
