import {
    getMeter,
    listMeters
} from './queries';
import {
    createMeter,
    deleteMeter,
    updateMeter,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Meter} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";


export type ListMeters = {
    listMeters: { items: Meter[] }
}

export type GetMeter = {
    getMeter: Meter
}

export type CreateMeter = {
    createMeter: Meter
}

export type UpdateMeter = {
    updateMeter: Meter
}

export type DeleteMeter = {
    deleteMeter: string
}


const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListMeters>>({
                                                                            query: listMeters,
                                                                            variables: {filter: filter},
                                                                        });
        return response.data?.listMeters.items;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetMeter>>({
                                                                          query: getMeter,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getMeter;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateMeter>>({
                                                                             query: createMeter,
                                                                             variables: {input: payload},
                                                                         });
        console.debug('in the service response', response);
        notifySuccess('Meter created');
        return response.data?.createMeter;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Meter) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateMeter>>({
                                                                             query: (updateMeter),
                                                                             variables: {input: record},
                                                                         });
        notifySuccess('Meter updated')
        return response.data?.updateMeter;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteMeter>>({
                                                                             query: (deleteMeter),
                                                                             variables: {input: {id: id}},

                                                                         });
        notifySuccess('Meter deleted')
        return response.data?.deleteMeter;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
